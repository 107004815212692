import {
  useEffect,
  useMemo,
  useRef,
  useState,
  type ChangeEvent,
  type Dispatch,
  type SetStateAction
} from 'react';
import { useTranslation } from 'react-i18next';
import { HiExclamationCircle as ExclamationIcon } from 'react-icons/hi2';
import { Banner, Button, Dialog } from '@knack/asterisk-react';

import { Route } from '@/enums';
import { type SampleApp } from '@/types/apps';
import { useAppMutation } from '@/hooks/api/mutations/useAppMutation';
import { useAccountQuery } from '@/hooks/api/queries/useAccountQuery';
import { useAppsQuery } from '@/hooks/api/queries/useAppsQuery';
import { useSampleAppsQuery } from '@/hooks/api/queries/useSampleAppsQuery';
import { useSession } from '@/hooks/useSession';
import { usePostSignUpContext } from '@/contexts/PostSignUpContext';
import { shouldSendAnalytics } from '@/utils/analytics';
import { getErrorMessage } from '@/utils/errors';
import { isFlagEnabled } from '@/utils/flagsmith';
import { rudderStackAnalytics } from '@/utils/rudderstack';
import {
  AppCreateType,
  BUILDER_NEXT_IMPORT_PATH,
  DEFAULT_SAMPLES,
  INDUSTRIES,
  SAMPLE_APPS_PRIORITIZED_DISPLAY_ORDER,
  SETUP_WIZARD_FILTERS,
  TOP_SAMPLE_APPS
} from '@/pages/apps/create-app/constants';
import { AiModal } from './ai-form/AiModal';
import { NavItem } from './template-apps/NavItem';
import { NavItemList } from './template-apps/NavItemList';
import { SetupWizardSection } from './template-apps/SetupWizardSection';
import { TemplateGallerySection } from './template-apps/TemplateGallerySection';

type Props = {
  open: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  variationId?: string | null;
  setIsAppLoading?: (isLoading: boolean) => void;
};

export function CreateAppFromSampleModal({
  open,
  setIsOpen,
  variationId = null,
  setIsAppLoading = () => {}
}: Props) {
  const { data: account } = useAccountQuery();
  const { data: application } = useAppsQuery();
  const { createApp } = useAppMutation();
  const [t] = useTranslation();
  const { data, isLoading, error: sampleQueryError } = useSampleAppsQuery();
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedSample, setSelectedSample] = useState('');
  const session = useSession();
  const listRef = useRef<HTMLDivElement>(null);
  const { createAppFromSample, deleteApp } = useAppMutation();
  const { isSetupWizardEnabled, setIsSetupWizardEnabled } = usePostSignUpContext();
  const isSetupWizardLocation = window.location.pathname.includes(Route.SetupWizard);
  const [selectedTab, setSelectedTab] = useState(isSetupWizardLocation ? 'portals' : 'suggested');
  const [shouldShowAiModal, setShouldShowAiModal] = useState(false);
  const [appCreationError, setAppCreationError] = useState('');

  const filterSetupWizardCategories = (samples: SampleApp[]) => {
    switch (selectedTab) {
      case 'other':
        return samples.filter((sample) => sample.categories === undefined);

      case 'portals':
        return samples.filter(
          (sample) =>
            sample.title.toLowerCase().includes('portal') ||
            sample.description.toLowerCase().includes('portal')
        );

      case 'directories':
        return samples.filter(
          (sample) =>
            sample.title.toLowerCase().includes('directory') ||
            sample.description.toLowerCase().includes('directory')
        );

      case 'education':
        return samples.filter((sample) => sample.categories?.includes('schools'));

      case 'project-management':
        return samples.filter(
          (sample) =>
            sample.title.toLowerCase().includes('project management') ||
            sample.description.toLowerCase().includes('project management')
        );

      case 'document-management':
        return samples.filter(
          (sample) =>
            sample.title.toLowerCase().includes('document management') ||
            sample.description.toLowerCase().includes('document management')
        );

      case 'hr':
        return samples.filter(
          (sample) =>
            sample.title.toLowerCase().includes('employee') ||
            sample.description.toLowerCase().includes('employee')
        );

      default:
        return samples.filter((sample) => sample.categories?.includes(selectedTab));
    }
  };

  const sampleApps = useMemo(() => {
    let samples = data ?? [];
    const defaultSamples = samples.filter((sample) => DEFAULT_SAMPLES.includes(sample.slug));

    if (searchQuery) {
      return samples.filter(
        (app: SampleApp) =>
          app.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
          app.description.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    if (selectedTab === 'suggested') {
      const userIndustry = session.user.marketing?.questionnaire?.industry;
      const suggested = samples.filter(
        (sample: SampleApp) => userIndustry && sample.industries?.includes(userIndustry)
      );
      samples = suggested.length > 0 ? suggested : defaultSamples;
    } else if (selectedTab === 'top') {
      samples = samples.filter((sample) => TOP_SAMPLE_APPS.includes(sample.slug));
    } else if (isSetupWizardLocation) {
      samples = filterSetupWizardCategories(samples);
    } else if (selectedTab !== 'all') {
      samples = samples.filter((sample) => sample.industries?.includes(selectedTab));
    }

    return samples.sort((a, b) => {
      let indexA = SAMPLE_APPS_PRIORITIZED_DISPLAY_ORDER.indexOf(a.slug);
      let indexB = SAMPLE_APPS_PRIORITIZED_DISPLAY_ORDER.indexOf(b.slug);

      if (indexA === -1) {
        indexA = SAMPLE_APPS_PRIORITIZED_DISPLAY_ORDER.length;
      }

      if (indexB === -1) {
        indexB = SAMPLE_APPS_PRIORITIZED_DISPLAY_ORDER.length;
      }

      return indexA - indexB;
    });
  }, [data, selectedTab, searchQuery, session.user.marketing, isSetupWizardLocation]);

  useEffect(() => {
    if (selectedTab !== 'all') {
      setSearchQuery('');
    }
    setSelectedSample('');
  }, [selectedTab]);

  const handleOnCloseModal = () => {
    if (shouldShowAiModal) {
      setShouldShowAiModal(true);
    } else if (!shouldShowAiModal) {
      setIsOpen(false);
      if (isSetupWizardEnabled || isSetupWizardLocation) {
        setIsAppLoading(true);
        setIsSetupWizardEnabled(false);
        window.location.href = `${import.meta.env.PUBLIC_DASHBOARD_URL}/${Route.Apps}`;
      }
    }
  };

  const handleSampleCreateApp = () => {
    const sample = data?.find((s) => s.id === selectedSample);
    if (!sample) {
      return;
    }
    const sampleData = {
      appId: sample.id,
      templateSlug: sample.slug,
      appDescription: sample.description
    };
    if (isSetupWizardEnabled && account?.applications && account.applications.length > 0) {
      // eslint-disable-next-line no-underscore-dangle
      const appId = account.applications[0]._id;
      if (appId) {
        deleteApp.mutate(appId);
      }
    }
    createAppFromSample.mutate(sampleData, {
      onSuccess: async (response) => {
        setAppCreationError('');
        if (shouldSendAnalytics(session.user.email)) {
          if (isSetupWizardLocation) {
            await window.analytics?.track('Setup Wizard: New App From Sample Created', {
              app: sample.slug,
              groupId: session.account.id,
              category: t(
                `components.onboarding.questionnaire.questions.categories.options.${selectedTab.replace('-', '_')}`
              )
            });
            await rudderStackAnalytics.track('Setup Wizard: New App From Sample Created', {
              app: sample.slug,
              groupId: session.account.id,
              category: t(
                `components.onboarding.questionnaire.questions.categories.options.${selectedTab.replace('-', '_')}`
              )
            });
          } else {
            await window.analytics?.track('New App From Sample Created', {
              app: sample.title,
              groupId: session.account.id
            });
            await rudderStackAnalytics.track('New App From Sample Created', {
              app: sample.title,
              groupId: session.account.id
            });
          }
        }
        if (isFlagEnabled('full_nextgen_access')) {
          window.location.href = `${import.meta.env.PUBLIC_BUILDER_NEXT_URL}/${session.account.slug}/${response.app.slug}/tables`;
        } else {
          window.location.href = `${import.meta.env.PUBLIC_BUILDER_URL}/${session.account.slug}/${response.app.slug}/schema/list`;
        }
      },
      onError: (error) => {
        const message = getErrorMessage(error, t('components.create_app.create_app_error_message'));
        setAppCreationError(message);
      }
    });
  };

  const handleSearchOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
    setSelectedTab('all');
  };

  const handleTestOrImportData = async (caseType: string) => {
    setIsOpen(false);
    setIsSetupWizardEnabled(false);

    const appData = {
      appDescription: '',
      appOrigin: AppCreateType.Import,
      shouldCreateWithTable: true,
      shouldCreateDefaultUserRoles: true
    };

    switch (caseType) {
      case 'test':
      case 'import':
      case 'scratch':
        setIsAppLoading(true);
        try {
          const response = await createApp.mutateAsync(appData);
          let builderPath = '';
          let builderDomain = isFlagEnabled('full_nextgen_access')
            ? import.meta.env.PUBLIC_BUILDER_NEXT_URL
            : import.meta.env.PUBLIC_BUILDER_URL;
          let appSlug = '';
          if (caseType === 'scratch') {
            builderPath = isFlagEnabled('full_nextgen_access')
              ? 'tables/object_4'
              : 'schema/list/objects/object_4/fields';
            appSlug = application[0]?.slug ?? t('components.create_app.default_app_slug');
          } else {
            builderPath = `${BUILDER_NEXT_IMPORT_PATH}?new=true`;
            builderDomain = import.meta.env.PUBLIC_BUILDER_NEXT_URL;
            appSlug = response.application.slug;
          }

          window.location.href = `${builderDomain}/${account?.slug}/${appSlug}/${builderPath}`;
        } catch (error) {
          setIsAppLoading(false);
          setIsOpen(true);
          const message = getErrorMessage(
            error,
            t('components.create_app.create_app_error_message')
          );
          setAppCreationError(message);
        }
        break;

      case 'ai':
        setShouldShowAiModal(true);
        break;
      default:
        if (isFlagEnabled('full_nextgen_access')) {
          window.location.href = `${import.meta.env.PUBLIC_BUILDER_NEXT_URL}/${account?.slug}/${application[0]?.slug ?? t('components.create_app.default_app_slug')}/tables/object_4`;
        } else {
          window.location.href = `${import.meta.env.PUBLIC_BUILDER_URL}/${account?.slug}/${application[0]?.slug ?? t('components.create_app.default_app_slug')}/schema/list/objects/object_4/fields`;
        }
        break;
    }
  };

  const buildDialogTitleClassName = () => {
    if (isSetupWizardLocation && variationId === 'three_cta') {
      return 'flex flex-col border-0 p-6';
    }
    return 'flex flex-col border-b border-subtle p-6';
  };

  return (
    <>
      <Dialog open={open} onOpenChange={handleOnCloseModal}>
        <Dialog.Content shouldHideCloseButton={isSetupWizardLocation} width="lg">
          <Dialog.MainContent className="flex max-h-[75vh] flex-col p-0">
            <Dialog.Title className={buildDialogTitleClassName()}>
              <div className="text-center text-xl font-medium text-emphasis">
                {isSetupWizardLocation
                  ? t('components.setup_wizard.title')
                  : t('components.create_app.select_sample_app')}
              </div>
              {appCreationError && (
                <Banner
                  intent="destructive"
                  icon={ExclamationIcon}
                  title={t('components.create_app.create_app_error_title')}
                  className="my-4"
                  closeMode="text"
                >
                  <Banner.Message className="text-xs">{appCreationError}</Banner.Message>
                </Banner>
              )}
              <div className="mt-2 text-center">
                {isSetupWizardLocation ? (
                  <SetupWizardSection handleTestOrImportData={handleTestOrImportData} />
                ) : (
                  t('components.create_app.sample_app_dialog_description')
                )}
              </div>
            </Dialog.Title>
            {isSetupWizardLocation && variationId === 'three_cta' && (
              <div className="text-center">{t('components.setup_wizard.select_template')}</div>
            )}
            <div className="flex overflow-auto">
              <div className="hidden overflow-y-auto border-r border-subtle p-6 sm:block">
                {!isSetupWizardLocation && (
                  <>
                    <NavItem
                      isActive={selectedTab === 'suggested'}
                      title={t('components.create_app.sample_suggested')}
                      onClick={() => setSelectedTab('suggested')}
                    />
                    <NavItem
                      isActive={selectedTab === 'top'}
                      title={t('components.create_app.sample_top')}
                      onClick={() => setSelectedTab('top')}
                    />
                    <NavItem
                      isActive={selectedTab === 'all'}
                      title={t('components.create_app.sample_all')}
                      onClick={() => setSelectedTab('all')}
                    />
                    <div className="mx-2 mb-3 mt-2 h-px bg-subtle" />
                  </>
                )}
                {!isSetupWizardLocation && (
                  <div className="mb-1 w-full rounded-lg p-2 text-xs text-subtle">
                    {t(`components.create_app.sample_filter`)}
                  </div>
                )}
                <NavItemList
                  selectedTab={selectedTab}
                  setSelectedTab={setSelectedTab}
                  filters={isSetupWizardLocation ? SETUP_WIZARD_FILTERS : INDUSTRIES}
                  isSetupWizard={isSetupWizardLocation}
                />
              </div>
              <div className="flex flex-1 flex-col gap-6 overflow-y-auto p-6" ref={listRef}>
                <TemplateGallerySection
                  sampleApps={sampleApps}
                  selectedTab={selectedTab}
                  search={searchQuery}
                  handleSearchOnChange={handleSearchOnChange}
                  isLoading={isLoading}
                  error={sampleQueryError?.message ?? null}
                  setSelectedSample={setSelectedSample}
                  selectedSample={selectedSample}
                  isSetupWizard={isSetupWizardLocation}
                />
              </div>
            </div>
          </Dialog.MainContent>
          <Dialog.Footer className="gap-4 border-t border-subtle">
            <Button
              disabled={createAppFromSample.isPending}
              onClick={handleOnCloseModal}
              intent="minimal"
            >
              {t('actions.close')}
            </Button>
            <Button
              disabled={isLoading || !selectedSample}
              onClick={handleSampleCreateApp}
              isLoading={createAppFromSample.isPending}
            >
              {t('components.create_app.create_app')}
            </Button>
          </Dialog.Footer>
        </Dialog.Content>
      </Dialog>
      {shouldShowAiModal && (
        <AiModal
          open
          onClose={(event?: string) => {
            if (event) {
              setIsOpen(true);
              setShouldShowAiModal(false);
            } else {
              handleOnCloseModal();
            }
          }}
        />
      )}
    </>
  );
}

export default CreateAppFromSampleModal;
