import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Banner, Button, Card, Divider, Spinner, useToast } from '@knack/asterisk-react';

import { AddonsType } from '@/enums/addons';
import { type Account, type BillingPlan } from '@/types/account';
import {
  useAddonPlanMutation,
  type UpdateTransactionsPackPayload
} from '@/hooks/api/mutations/useAddonMutation';
import { Flows } from '@/hooks/api/queries/useFlowsQuery';
import { getRenewalDay } from '@/pages/settings/billing/overview/usageHelper';
import { AutoIncrease } from './auto-increase/AutoIncrease';
import { Counter } from './Counter';
import { TransactionNotInitialized } from './TransactionsNotInitialized';

export type TransactionsItem = {
  transactions: number;
  price: number;
  stripeId: string;
  quantity: number;
};

export const initialTransactionsItems: TransactionsItem[] = [
  {
    transactions: 1000,
    price: 19,
    stripeId: import.meta.env.PUBLIC_TRANSACTIONS_ADDON_1000,
    quantity: 0
  },
  {
    transactions: 10000,
    price: 99,
    stripeId: import.meta.env.PUBLIC_TRANSACTIONS_ADDON_10000,
    quantity: 0
  },
  {
    transactions: 100000,
    price: 399,
    stripeId: import.meta.env.PUBLIC_TRANSACTIONS_ADDON_100000,
    quantity: 0
  }
];

interface TransactionAddonsProps {
  currentTransactionsPlan: BillingPlan[] | null;
  account: Account;
}

export function TransactionsAddon({ currentTransactionsPlan, account }: TransactionAddonsProps) {
  const [t] = useTranslation();
  const [transactionsItems, setTransactionsItems] = useState(initialTransactionsItems);
  const [hasTransactionChange, setHasTransactionChange] = useState(false);
  const { updateTransactionPacksMutation } = useAddonPlanMutation();
  const { presentToast } = useToast();
  const { data: usage } = Flows.useTransactionsUsageQuery(account);
  const { data: flowsStatus } = Flows.useAccountFlowsStatusQuery();
  const fillTransactionsQuantities = () => {
    if (!currentTransactionsPlan) return;

    const updatedItems = transactionsItems.map((item) => {
      const plan = currentTransactionsPlan.find((p) => p.id === item.stripeId);
      if (plan) {
        return { ...item, quantity: plan.quantity };
      }
      return item;
    });

    setTransactionsItems(updatedItems);
  };

  useEffect(() => {
    fillTransactionsQuantities();
  }, [currentTransactionsPlan]);

  const onCounterChange = (item: TransactionsItem, quantity: number) => {
    setHasTransactionChange(true);

    const updatedItems = transactionsItems.map((i) =>
      i.stripeId === item.stripeId ? { ...i, quantity } : i
    );

    setTransactionsItems(updatedItems);
  };

  const handleUpdateTransactionAddons = () => {
    const transactionPacksPayload: UpdateTransactionsPackPayload = {
      transactionsPacks: []
    };

    transactionsItems.map((item) =>
      transactionPacksPayload.transactionsPacks?.push({
        amount: item.transactions,
        quantity: item.quantity,
        id: item.stripeId
      })
    );

    updateTransactionPacksMutation.mutate(transactionPacksPayload, {
      onSuccess: (response) => {
        setHasTransactionChange(false);
        presentToast({
          title: t(
            `components.billing.addons.transactions.success_${response.transactionsDiff > 0 ? 'increase' : 'decrease'}`
          )
        });
      },
      onError: () => {
        presentToast({
          title: t('error.generic_error')
        });
      }
    });
  };

  const shouldShowWarningBanner = () => {
    if (!usage) return false;

    return usage.trxPercentageUsageCurrentMonth >= 90;
  };

  return (
    <Card className="flex flex-col p-6 sm:p-6">
      <h2 className="mb-1 text-xl font-medium text-emphasis">
        {t('components.billing.addons.transactions.title')}
      </h2>
      <p className="mb-6">{t('components.billing.addons.transactions.description')}</p>
      {flowsStatus?.isInitialized ? (
        <>
          <div className="mb-6">
            {shouldShowWarningBanner() && (
              <Banner intent="warning" className="mb-4">
                {t('components.billing.addons.transactions.90_limit_reached')}
              </Banner>
            )}
            {transactionsItems.map((item) => (
              <div
                key={item.stripeId}
                className="flex flex-wrap justify-between gap-4 rounded-lg border border-default px-4 py-3.5 [&:not(:last-child)]:mb-6"
              >
                <div>
                  <div className="mb-2 text-emphasis">
                    <span>
                      {t('components.billing.addons.transactions.item_limit', {
                        numberOfTransactions: item.transactions.toLocaleString()
                      })}
                    </span>
                  </div>
                  <p className="text-xs">
                    {t('components.billing.addons.transactions.item_price', {
                      price: item.price.toLocaleString()
                    })}
                  </p>
                </div>
                <div>
                  <Counter item={item} onChange={onCounterChange} />
                </div>
              </div>
            ))}
          </div>
          <Button
            onClick={handleUpdateTransactionAddons}
            className="mb-6 flex max-w-32 self-end"
            disabled={!hasTransactionChange || updateTransactionPacksMutation.isPending}
          >
            {updateTransactionPacksMutation.isPending ? (
              <Spinner size="sm" />
            ) : (
              t('components.billing.addons.transactions.save')
            )}
          </Button>
          <Divider className="mb-6" />
          {usage && (
            <AutoIncrease
              addonType={AddonsType.Transactions}
              disabled={account.isTrial || account.product_plan.id.includes('starter_')}
              checked={account.billing?.auto_increase?.transactions ?? true}
              renewalDay={getRenewalDay(usage.datePaidPeriodEnd)}
            />
          )}
        </>
      ) : (
        <div className="flex h-full items-center justify-center">
          <TransactionNotInitialized transactionsNumber={usage?.trxLimitCurrentMonth || 0} />
        </div>
      )}
    </Card>
  );
}
