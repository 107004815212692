import { useEffect, useState, type ReactNode } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useLocation, useSearchParams } from 'react-router-dom';
import { Button, KnackLogo } from '@knack/asterisk-react';
import Cookies from 'js-cookie';

import { type Credentials } from '@/types/auth';
import { useSignUpMutation } from '@/hooks/api/mutations/useSignUpMutation';
import { usePostSignUpContext } from '@/contexts/PostSignUpContext';
import { pushToDataLayer, shouldSendAnalytics } from '@/utils/analytics';
import { MARKETING_PAGE_URL } from '@/utils/constants';
import { getAnonymousFlagValue } from '@/utils/flagsmith';
import { getGAnalyticsProperties, getReferral } from '@/utils/querystrings';
import { isRudderStackEnabled, rudderStackAnalytics } from '@/utils/rudderstack';
import { cn } from '@/utils/tailwind';
import { SignUpForm } from '@/pages/sign-up/SignUpForm';
import { TermsAndPrivacy } from '@/pages/sign-up/TermsAndPrivacy';
import ProductBackgroundWebp from '@/assets/img/product-background.png?w=1920&h=1080&format=webp&imagetools';
import ProductBackground from '@/assets/img/product-background.png?w=1920&h=1080&imagetools';

interface SignUpContainerProps {
  children: ReactNode;
}

function SignUpMain({ children, className }: SignUpContainerProps & { className?: string }) {
  return <main className={cn('flex min-h-screen', className)}>{children}</main>;
}

function SignUpSection({ children }: SignUpContainerProps) {
  return (
    <section className="mb-20 mt-24 flex-auto items-center justify-center md:mb-0 md:mt-0 md:flex">
      {children}
    </section>
  );
}

function SignUpSectionContainer({ children }: SignUpContainerProps) {
  return (
    <div className="container mb-6 flex max-w-6xl flex-col-reverse gap-8 md:flex-row md:gap-20">
      {children}
    </div>
  );
}

function SignUpFormContainer({
  children,
  className
}: SignUpContainerProps & { className?: string }) {
  return (
    <div
      className={cn(
        'mx-auto flex w-full max-w-[500px] flex-col justify-center sm:text-center md:text-left',
        className
      )}
    >
      {children}
    </div>
  );
}

function SignUpFormIntro({ className = '' }: { className?: string }) {
  const [t] = useTranslation();

  return (
    <div className={cn('mb-8 flex flex-col items-center gap-2', className)}>
      <h1 className="text-3xl" data-testid="auth-signup-header-text">
        {t('components.sign_up.title')}
      </h1>
      <div className="text-subtle">{t('components.sign_up.description')}</div>
    </div>
  );
}

interface SignUpProps {
  onSubmit: (data: Credentials) => void;
  signUpError: string;
  isLoading: boolean;
}

function SignUpEmbedded({ onSubmit, signUpError, isLoading }: SignUpProps) {
  const sendHeightUpdate = () => {
    const height = document.documentElement.scrollHeight;
    window.parent.postMessage(
      {
        type: 'iframeResize',
        height
      },
      MARKETING_PAGE_URL
    );
  };

  useEffect(() => {
    sendHeightUpdate();

    window.addEventListener('resize', sendHeightUpdate);

    const observer = new MutationObserver(sendHeightUpdate);
    observer.observe(document.body, {
      childList: true,
      subtree: true,
      attributes: true
    });

    return () => {
      window.removeEventListener('resize', sendHeightUpdate);
      observer.disconnect();
    };
  }, []);

  return (
    <SignUpMain className="min-h-0">
      <SignUpSection>
        <SignUpSectionContainer>
          <SignUpFormContainer>
            <SignUpForm
              onSubmit={onSubmit}
              signUpError={signUpError}
              isLoading={isLoading}
              className="mt-8"
            />
          </SignUpFormContainer>
        </SignUpSectionContainer>
      </SignUpSection>
    </SignUpMain>
  );
}

export function SignUp() {
  const [t, { language }] = useTranslation();
  const [signUpError, setSignUpError] = useState('');
  const signUp = useSignUpMutation();
  const { setIsSetupWizardEnabled } = usePostSignUpContext();
  const { search } = useLocation();
  const [searchParams] = useSearchParams();

  const embedSearchParam = searchParams.get('embed');
  const enableEmbeddedForm = embedSearchParam === 'true';

  useEffect(() => {
    const params = new URLSearchParams(search);
    const referralCookie = Cookies.get('referral');

    if (window.analytics || isRudderStackEnabled()) {
      // Assign Google Analytics tracking properties to Cookies to be captured by the Server
      getGAnalyticsProperties().forEach(
        ({ key, value }) => value !== undefined && Cookies.set(key, value, { expires: 30 })
      );

      if (
        referralCookie ||
        !document.referrer.includes('dashboard.knack.com') ||
        params.get('referral') ||
        params.get('ref')
      ) {
        window.analytics?.track('Sign Up Referral', {
          referral: getReferral()
        });
        void rudderStackAnalytics.track('Sign Up Referral', {
          referral: getReferral()
        });
      }
    }
  }, [search]);

  /**
   * Check if there is a Google Ad cookie from the Website page and if so, add the gclid and clientid to the credentials
   * @param credentials The info from the Sign-up form. Includes username and password
   * @returns
   */
  const checkGoogleCookie = (credentials: Credentials) => {
    const fromGoogleAd = Cookies.get('kn_ad');
    if (fromGoogleAd) {
      const knAdCookieParts = fromGoogleAd.split('~');
      return {
        ...credentials,
        gclid: knAdCookieParts[0],
        clientid: knAdCookieParts[1]
      };
    }
    return credentials;
  };

  /**
   * Validates the request credentials, in case we have enabled the onboarding questionnaire in
   * it will display this questionnaire after a successful validation, when not enabled
   * it will call submitSignUp function directly.
   */
  const onSubmit = async (data: Credentials) => {
    const credentials = checkGoogleCookie(data);

    if (!credentials) {
      setSignUpError(t('errors.generic_error'));
      return;
    }

    await signUp.mutateAsync(
      { credentials },
      {
        onSuccess: async (session) => {
          if (shouldSendAnalytics(session.user.email)) {
            pushToDataLayer('signup-email');
          }
        },
        onError: (err: any) => {
          const errorCode = err.response?.data?.errors[0]?.message;

          const errorMessage =
            !errorCode || errorCode === 'generic_error'
              ? 'errors.generic_error'
              : `components.auth.${errorCode}`;

          setSignUpError(t(errorMessage));
        }
      }
    );
  };

  useEffect(() => {
    void getAnonymousFlagValue('setup_wizard_v1').then((setupWizardFlagValue) => {
      if (setupWizardFlagValue) {
        setIsSetupWizardEnabled(true);
      }
    });
  }, []);

  if (enableEmbeddedForm) {
    return (
      <SignUpEmbedded onSubmit={onSubmit} signUpError={signUpError} isLoading={signUp.isPending} />
    );
  }

  return (
    <SignUpMain>
      <picture>
        <source srcSet={ProductBackgroundWebp} type="image/webp" />
        <source srcSet={ProductBackground} type="image/png" />
        <img
          src={ProductBackground}
          alt={t('components.sign_up.background_alt')}
          className="absolute z-0 h-full w-full object-cover"
        />
      </picture>
      <div className="absolute z-10 h-full w-full bg-black/50 backdrop-blur" />
      <SignUpFormContainer className="z-20 my-auto max-w-[596px] rounded-xl bg-default p-12 pb-6">
        <div className="mx-auto mb-8 text-brand">
          <KnackLogo />
        </div>

        <SignUpFormIntro className="text-center" />
        <SignUpForm onSubmit={onSubmit} signUpError={signUpError} isLoading={signUp.isPending} />
        <TermsAndPrivacy />
      </SignUpFormContainer>
      <Helmet>
        <title lang={language}>{`${t('components.sign_up.title')} • ${t(
          'titles.app_name'
        )}`}</title>
      </Helmet>
    </SignUpMain>
  );
}

export function SignUpIFrameFallback() {
  const [t] = useTranslation();
  const [searchParams] = useSearchParams();

  const searchParamsSerialized = searchParams.toString();
  const urlSearchParams = searchParamsSerialized ? `?${searchParamsSerialized}` : '';

  return (
    <div className="flex h-screen flex-col items-center justify-center gap-4">
      <p>{t('components.account.already_signed')}</p>
      <Button
        className="block"
        onClick={() => {
          window.open(`${import.meta.env.PUBLIC_DASHBOARD_URL}${urlSearchParams}`, '_blank');
        }}
      >
        {t('components.account.go_to_dashboard')}
      </Button>
    </div>
  );
}
