import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { HiOutlineExternalLink as ExternalIcon } from 'react-icons/hi';
import { zodResolver } from '@hookform/resolvers/zod';
import { Input, Label, Textarea } from '@knack/asterisk-react';
import { type AxiosError } from 'axios';
import { t as i18n } from 'i18next';
import { z } from 'zod';

import { useAiQueryMutation } from '@/hooks/api/mutations/useAiQueryMutation';
import { useBillingOverviewQuery } from '@/hooks/api/queries/useBillingOverviewQuery';
import { useSession } from '@/hooks/useSession';
import { useTranslationArray } from '@/hooks/useTranslationArray';
import { shouldSendAnalytics } from '@/utils/analytics';
import { getErrorMessage } from '@/utils/errors';
import { isFlagEnabled } from '@/utils/flagsmith';
import { rudderStackAnalytics } from '@/utils/rudderstack';
import { FormControl } from '@/components/ui/FormControl';
import { LoadingModal } from '@/components/ui/LoadingModal';
import { AiSuggestions } from './AiSuggestions';

const maxAverageWordLength = 10;
const maxCount = 400;
const illegalCharactersRegex = /[<>]/;

const aiInputSchema = z.object({
  query: z.string().refine((value) => {
    const isPromptBlank = value.trim() === '';
    const averageWordLength = value.replace(/\W/g, '').length / value.split(/\s/).length;
    const hasIllegalCharacters = illegalCharactersRegex.test(value);

    return !isPromptBlank && averageWordLength <= maxAverageWordLength && !hasIllegalCharacters;
  }, i18n('components.create_app.ai.invalid_query'))
});

export type AiInputSchemaType = z.infer<typeof aiInputSchema>;

export function AiInput({ setSubmitEnabled }: { setSubmitEnabled: (enabled: boolean) => void }) {
  const {
    register,
    setValue,
    clearErrors,
    getValues,
    handleSubmit,
    formState: { isSubmitting, errors }
  } = useForm<AiInputSchemaType>({
    resolver: zodResolver(aiInputSchema)
  });
  const [t] = useTranslation();
  const queryValue = getValues('query');
  const session = useSession();
  const { data: billingData } = useBillingOverviewQuery();
  const [serverError, setServerError] = useState('');
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [usedSuggestionCount, setUsedSuggestionCount] = useState(0);
  const hasErrors = !!(isFormDirty && queryValue && (serverError || errors.query));
  const { createAppFromAiQuery } = useAiQueryMutation();
  const creatingPhrases = useTranslationArray('components.create_app.creating');

  const onSubmit = (formData) => {
    if (errors.query) {
      return;
    }

    createAppFromAiQuery.mutate(formData.query, {
      onSuccess: async (data) => {
        let newAppUrl = '';
        if (isFlagEnabled('full_nextgen_access')) {
          newAppUrl = `${import.meta.env.PUBLIC_BUILDER_NEXT_URL}/${session.account.slug}/${
            data.slug
          }/pages?fromAi=true&quickView=true`;
        } else {
          newAppUrl = `${import.meta.env.PUBLIC_BUILDER_URL}/${session.account.slug}/${
            data.slug
          }/live-app-preview?fromAi=true`;
        }

        if (shouldSendAnalytics(session.user.email)) {
          await window.analytics?.track('AI - App Created', {
            groupId: session.account.id,
            app_id: data.id,
            account_id: session.account.id,
            customer_id: billingData?.customer?.id,
            user_id: session.user.id,
            query: formData.query,
            status: 'success',
            suggestionsUsed: usedSuggestionCount
          });
          await rudderStackAnalytics.track('AI - App Created', {
            groupId: session.account.id,
            app_id: data.id,
            account_id: session.account.id,
            customer_id: billingData?.customer?.id,
            user_id: session.user.id,
            query: formData.query,
            status: 'success',
            suggestionsUsed: usedSuggestionCount
          });
        }
        window.location.href = newAppUrl;
      },
      onError: (error) => {
        let message;

        if ((error as AxiosError).response?.status === 429) {
          message = t('components.create_app.ai.rate_limit_error');
        } else if ((error as AxiosError).response?.status === 504) {
          message = t('components.create_app.ai.high_traffic_error');
        } else if (!(error as AxiosError).response?.status && (error as AxiosError).request) {
          message = t('components.create_app.ai.connectivity_error');
        } else {
          // Something went wrong
          message = getErrorMessage(error, t('components.create_app.ai.ask_ai_error'));
        }

        /* v8 ignore next 9 */
        if (shouldSendAnalytics(session.user.email)) {
          window.analytics?.track('AI - Failed App Creation', {
            groupId: session.account.id,
            account_id: session.account.id,
            customer_id: billingData?.customer?.id,
            user_id: session.user.id,
            query: formData.query,
            message,
            status: 'failed',
            suggestionsUsed: usedSuggestionCount
          });
          void rudderStackAnalytics.track('AI - Failed App Creation', {
            groupId: session.account.id,
            account_id: session.account.id,
            customer_id: billingData?.customer?.id,
            user_id: session.user.id,
            query: formData.query,
            message,
            status: 'failed',
            suggestionsUsed: usedSuggestionCount
          });
        }
        setServerError(message);
      }
    });
  };
  const onChange = () => {
    setSubmitEnabled(getValues('query').length !== 0);
    setIsFormDirty(true);
    clearErrors();
    setServerError('');
  };
  const onSuggestionClick = (query) => {
    setValue('query', query);
    setUsedSuggestionCount(usedSuggestionCount + 1);
    setSubmitEnabled(getValues('query').length !== 0);
    setIsFormDirty(true);
    clearErrors();
    setServerError('');
  };

  return (
    <form
      id="ai-query-form"
      data-testid="create-app-ai-query-form"
      onChange={onChange}
      onSubmit={handleSubmit(onSubmit)}
    >
      <FormControl className="flex flex-col justify-start">
        <div className="flex">
          <Label className="w-full">
            {t('components.create_app.ai.describe_your_ideal_app')}
            <span className="ml-1 text-brand">*</span>
          </Label>
          <AiSuggestions onClick={onSuggestionClick} />
        </div>
        <Input.Container className="h-full w-full">
          <Textarea
            className="h-[286px] flex-none resize-none"
            autoFocus
            hideResize
            data-testid="create-app-ai-query-input"
            style={{ pointerEvents: 'all' }}
            title={t('components.create_app.ai.ask_ai')}
            disabled={isSubmitting}
            intent={hasErrors ? 'destructive' : 'default'}
            maxLength={maxCount}
            showCharCount
            {...register('query')}
          />
        </Input.Container>
        {hasErrors && (
          <FormControl.Message
            type={hasErrors ? 'error' : 'default'}
            className="mt-10 rounded-lg bg-destructive p-4 text-subtle"
          >
            {/* We're using just one Trans component to render all the AI errors.
                The errors need support for <a>, <br>, <span> and Icon components.
                Since we're already using the translation, we don't need to use the i18nKey prop. */}
            <Trans
              components={{
                // eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid
                a: <a />,
                br: <br />,
                span: <span />,
                ExternalIcon: <ExternalIcon />
              }}
            >
              {errors.query?.message || serverError}
            </Trans>
          </FormControl.Message>
        )}
        {queryValue?.length === maxCount && (
          <FormControl.Message
            type="default"
            className="text-subtle"
            data-testid="create-app-ai-max-limit-message"
          >
            {t('components.create_app.ai.max_ask_size', { maxCount })}
          </FormControl.Message>
        )}
      </FormControl>
      {(createAppFromAiQuery.isPending || createAppFromAiQuery.isSuccess) && (
        <LoadingModal phrases={creatingPhrases} />
      )}
    </form>
  );
}
