import { useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { MdArrowBack } from 'react-icons/md';
import { Link, useLocation } from 'react-router-dom';
import {
  AsteriskLogo,
  Banner,
  Button,
  Card,
  Input,
  KnackLogo,
  ThemeProviderContext
} from '@knack/asterisk-react';

import { Route } from '@/enums/routing';
import { cn } from '@/utils/tailwind';
import { FormErrorBanner } from '@/components/forms';
import { FormControl } from '@/components/ui/FormControl';
import { useForgotPasswordForm } from './useForgotPasswordForm';

interface LocationState {
  invalidLink: boolean;
}

export function ForgotPassword() {
  const [t, { language }] = useTranslation();
  const { isDarkMode } = useContext(ThemeProviderContext);
  const location = useLocation();
  const { invalidLink } = (location.state || {
    invalidLink: false
  }) as LocationState;

  const { register, handleSubmit, errors, isSubmitting, isSubmitSuccessful, onSubmitHandler } =
    useForgotPasswordForm();

  return (
    <main
      className={cn('flex min-h-screen flex-col', {
        'bg-brand-gradient': !isDarkMode
      })}
    >
      <div
        className={cn('mx-auto pb-12 pt-10 text-brand sm:mx-0 sm:pl-15', {
          'text-white': isDarkMode
        })}
      >
        <KnackLogo />
      </div>
      <section className="container flex flex-auto items-center justify-center">
        <Card className="flex w-full max-w-[600px] flex-col items-center justify-center px-7 py-14 sm:px-14 sm:py-16">
          {isSubmitSuccessful ? (
            <>
              <p className="mb-8 self-start">{t('components.forgot_password.confirmation')}</p>
              <div className="flex w-full items-center">
                <MdArrowBack className="mr-2" />
                <Link
                  className="hover:underline"
                  to={{ pathname: `/${Route.SignIn}`, search: location.search }}
                >
                  {t('components.forgot_password.return_sign_in')}
                </Link>
              </div>
            </>
          ) : (
            <>
              {invalidLink && (
                <Banner intent="destructive" className="mb-12 w-full text-center">
                  <Banner.Message>{t('components.reset_password.invalid_link')}</Banner.Message>
                </Banner>
              )}

              <div
                className={cn(
                  'mb-12 hidden h-14 w-16 text-white sm:block md:text-brand',
                  isDarkMode && 'md:text-white'
                )}
              >
                <AsteriskLogo />
              </div>
              <h1
                className="mb-4 self-start text-3xl sm:mb-6 sm:self-center sm:text-4xl"
                data-testid="reset-password-header-text"
              >
                {t('components.forgot_password.title')}
              </h1>
              <p className="mb-10">{t('components.forgot_password.description')}</p>

              <FormErrorBanner errors={errors} />

              <form
                onSubmit={handleSubmit(onSubmitHandler)}
                className="w-full"
                noValidate // This disables Chrome's native form validation on the email field
              >
                <FormControl>
                  <FormControl.Label htmlFor="email" intent={errors.email && 'destructive'}>
                    {t('attributes.user.email')}
                  </FormControl.Label>
                  <Input
                    title="Email"
                    id="email"
                    data-testid="reset-password-email-field"
                    type="email"
                    disabled={isSubmitting}
                    placeholder={t('attributes.user.email')}
                    intent={errors.email && 'destructive'}
                    {...register('email')}
                  />
                  {errors.email && (
                    <FormControl.Message type="error">{errors.email.message}</FormControl.Message>
                  )}
                </FormControl>
                <Button
                  type="submit"
                  isLoading={isSubmitting}
                  className="mt-8 w-full"
                  data-testid="reset-password-send-button"
                >
                  {t('components.forgot_password.submit')}
                </Button>
              </form>
              <p className="mt-4 self-start sm:self-center">
                {t('components.sign_in.no_account')}{' '}
                <Link
                  className="underline"
                  to={{ pathname: `/${Route.SignUp}`, search: location.search }}
                >
                  {t('components.sign_up.action')}
                </Link>
              </p>
            </>
          )}
        </Card>
      </section>
      <Helmet>
        <title lang={language}>{`${t('components.forgot_password.title')} • ${t(
          'titles.app_name'
        )}`}</title>
      </Helmet>
    </main>
  );
}
