import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Banner, Progress } from '@knack/asterisk-react';

import { useSampleAppsQuery } from '@/hooks/api/queries/useSampleAppsQuery';
import { useSession } from '@/hooks/useSession';
import { shouldSendAnalytics } from '@/utils/analytics';
import { getFlagValue, isFlagEnabled, trackVariation } from '@/utils/flagsmith';
import { WizardLayout } from '@/components/layout/WizardLayout';
import {
  SETUP_WIZARD_INDUSTRIES_USE_CASES,
  SETUP_WIZARD_STEPS
} from '@/pages/apps/create-app/constants';
import { StepSelectCard } from './StepSelectCard';
import { StepSelectIndustry } from './StepSelectIndustry';

export function SetupWizardSteps() {
  const [t] = useTranslation();
  const session = useSession();
  const [currentStep, setCurrentStep] = useState(SETUP_WIZARD_STEPS[0]);
  const defaultPathForTranslation = 'components.setup_wizard.version_1.9';
  const { industries, useCases } = SETUP_WIZARD_INDUSTRIES_USE_CASES;
  const [selectedIndustry, setSelectedIndustry] = useState<string | undefined>(undefined);
  const [selectedUseCase, setSelectedUseCase] = useState<string | undefined>(undefined);
  const [otherUseCaseDetails, setOtherUseCaseDetails] = useState('');
  const { data: sampleApps, isLoading } = useSampleAppsQuery();

  const getTitleFromFilter = (key: string) =>
    t(`components.onboarding.questionnaire.questions.categories.options.${key.replace(/-/g, '_')}`);

  const shouldShowErrorBanner =
    selectedIndustry === t(`${defaultPathForTranslation}.step_1.text_1`) ||
    selectedUseCase === t(`${defaultPathForTranslation}.step_1.text_2`);

  const goBack = () => {
    setCurrentStep(SETUP_WIZARD_STEPS[0]);
  };

  const isAiCardFlagEnabled = isFlagEnabled('gt_setup_wizard_ai_button');
  const [aiCardFlagValue, setAiCardFlagValue] = useState<'personalized' | 'generate_ai' | null>(
    null
  );
  const getIndexOfStepForTexts = (step: string) => SETUP_WIZARD_STEPS.indexOf(step) + 1;

  useEffect(() => {
    if (isAiCardFlagEnabled) {
      const value = getFlagValue('gt_setup_wizard_ai_button');
      setAiCardFlagValue(value);
      void trackVariation({
        experimentId: 'gt_setup_wizard_ai_button',
        variationId: value,
        accountId: session.account.id,
        userEmail: session.user.email,
        shouldSendAnalytics
      });
    } else {
      setAiCardFlagValue('personalized');
    }
  }, []);

  return (
    <div className="p-18" data-testid="setup-wizard-steps">
      <WizardLayout
        contentWidth="full"
        shouldShowCloseButton={false}
        hasHeader={false}
        hasFooter={false}
        dataTestid="setup-wizard-layout"
      >
        {shouldShowErrorBanner && (
          <Banner intent="destructive" className="mb-6" data-testid="error-banner">
            {t(`${defaultPathForTranslation}.step_${getIndexOfStepForTexts(currentStep)}.error`)}
          </Banner>
        )}
        <div>
          <div className="text-xl font-medium text-emphasis" data-testid="setup-wizard-title">
            <h1>
              {t(`${defaultPathForTranslation}.step_${getIndexOfStepForTexts(currentStep)}.title`, {
                portal:
                  selectedUseCase && selectedUseCase !== 'other'
                    ? getTitleFromFilter(selectedUseCase)
                    : ''
              })}
            </h1>
          </div>
          {currentStep === SETUP_WIZARD_STEPS[1] && (
            <div className="text-lg font-medium text-subtle" data-testid="wizard-subtitle">
              {t(
                `${defaultPathForTranslation}.step_${getIndexOfStepForTexts(currentStep)}.subtitle`
              )}
            </div>
          )}
          <div className="my-4">
            <Progress
              className="max-w-[520px]"
              intent="brand"
              value={currentStep === SETUP_WIZARD_STEPS[0] ? 0 : 50}
              data-testid="progress-bar"
            />
          </div>
        </div>
        {currentStep === SETUP_WIZARD_STEPS[0] && (
          <StepSelectIndustry
            setCurrentStep={setCurrentStep}
            selectedIndustry={selectedIndustry}
            setSelectedIndustry={setSelectedIndustry}
            selectedUseCase={selectedUseCase}
            setSelectedUseCase={setSelectedUseCase}
            defaultPathForTranslation={defaultPathForTranslation}
            getTitleFromFilter={getTitleFromFilter}
            industries={industries}
            useCases={useCases}
            isLoading={isLoading}
            otherUseCaseDetails={otherUseCaseDetails}
            setOtherUseCaseDetails={setOtherUseCaseDetails}
          />
        )}
        {currentStep === SETUP_WIZARD_STEPS[1] && selectedIndustry && selectedUseCase && (
          <StepSelectCard
            selectedIndustry={selectedIndustry}
            selectedUseCase={selectedUseCase}
            sampleApps={sampleApps ?? []}
            defaultPathForTranslation={defaultPathForTranslation}
            onBack={goBack}
            getTitleFromFilter={getTitleFromFilter}
            otherUseCaseDetails={otherUseCaseDetails}
            aiCardFlagValue={
              isAiCardFlagEnabled && aiCardFlagValue ? aiCardFlagValue : 'personalized'
            }
            data-testid="step-select-card"
          />
        )}
      </WizardLayout>
    </div>
  );
}
