export type ApiAddonItem = {
  calls: number;
  price: number;
  quantity: number;
};
export type ApiAddonItemAction = 'upgrade' | 'downgrade' | 'remove';
export const legacyApiAddonItems: ApiAddonItem[] = [
  {
    calls: 25000,
    price: 30,
    quantity: 1
  },
  {
    calls: 50000,
    price: 60,
    quantity: 2
  },
  {
    calls: 75000,
    price: 90,
    quantity: 3
  },
  {
    calls: 100000,
    price: 120,
    quantity: 4
  }
];

export const currentApiAddonItems: ApiAddonItem[] = [
  {
    calls: 25000,
    price: 37.5,
    quantity: 1
  },
  {
    calls: 50000,
    price: 75,
    quantity: 2
  },
  {
    calls: 75000,
    price: 112.5,
    quantity: 3
  },
  {
    calls: 100000,
    price: 150,
    quantity: 4
  }
];

export const transactionAddonPacks = [
  {
    testId: 'price_1PivxjEuC8nKmbo5gh9xjtkg',
    prodId: 'addon_transactions_241001_1',
    transactions: 1000,
    price: 19
  },
  {
    testId: 'price_1PmrpLEuC8nKmbo5QVBCLbrs',
    prodId: 'addon_transactions_241001_10',
    transactions: 10000,
    price: 99
  },
  {
    testId: 'price_1PmrpmEuC8nKmbo5XKmJWQ5I',
    prodId: 'addon_transactions_241001_100',
    transactions: 100000,
    price: 399
  }
];

// Records and Storage Add-ons are now supported only for Agency plans
export const recordsAddonItem = {
  records: 100000,
  price: 65
};

export const storageAddonItem = {
  storage: 50,
  price: 30
};

export const featureItem = {
  seo_141121: 25,
  sso_141121: 35,
  sso_230901: 45,
  sso_241001: 50
} as const;
