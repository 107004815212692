import { Trans, useTranslation } from 'react-i18next';
import { Banner, Button, Input } from '@knack/asterisk-react';

import { type Credentials } from '@/types/auth';
import { FormErrorBanner } from '@/components/forms';
import { FormControl } from '@/components/ui/FormControl';
import { useSignIn2faForm } from '@/pages/sign-in/useSignIn2faForm';

export function SignIn2fa({ credentials }: { credentials: Credentials }) {
  const [t] = useTranslation();

  const { register, handleSubmit, errors, isSubmitting, isValid, onSubmitHandler } =
    useSignIn2faForm({ credentials });

  return (
    <>
      <FormErrorBanner errors={errors} />
      <form onSubmit={handleSubmit(onSubmitHandler)} className="w-full">
        <FormControl>
          <h1 className="mb-4 self-start text-3xl sm:mb-6 sm:self-center sm:text-4xl">
            {t('components.sign_in.enter_2fa_code')}
          </h1>
          {errors.code2fa && (
            <Banner intent="destructive" className="mb-8">
              <Banner.Message>{errors.code2fa.message}</Banner.Message>
            </Banner>
          )}
          <FormControl.Label htmlFor="email" intent={errors.code2fa && 'destructive'}>
            {t('components.sign_in.6_digit_code')}
          </FormControl.Label>
          <Input
            title={t('components.sign_in.6_digit_code')}
            id="2fa-number"
            type="number"
            disabled={isSubmitting}
            placeholder={t('components.sign_in.6_digit_code_placeholder')}
            intent={errors.code2fa && 'destructive'}
            autoFocus
            {...register('code2fa')}
          />
        </FormControl>
        <Button
          type="submit"
          isLoading={isSubmitting}
          disabled={!isValid}
          className="mb-8 mt-8 w-full"
        >
          {t('components.sign_in.action')}
        </Button>
      </form>
      <p className="text-center sm:mx-8">
        <Trans i18nKey="components.sign_in.2fa_help">
          <a
            href="mailto:support@knack.com"
            target="_blank"
            rel="noreferrer"
            className="text-emphasis underline"
          >
            <strong>Knack support</strong>
          </a>
        </Trans>
      </p>
    </>
  );
}
