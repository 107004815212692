import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

import { type AccountAddons } from '@/types/billing';
import { queryKeys } from '@/hooks/api/queryKeys';
import { useSession } from '@/hooks/useSession';

async function getAddons() {
  const { data } = await axios.get<AccountAddons>('/v1/dashboard/billing/addons', {
    withCredentials: true
  });

  return data;
}

export function useAddonsPlanQuery() {
  const session = useSession();

  return useQuery({
    queryKey: [queryKeys.billing.addons, session.account.id],
    queryFn: getAddons,
    staleTime: 1000 * 30
  });
}
