import { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import {
  AsteriskLogo,
  Banner,
  Card,
  Divider,
  KnackLogo,
  ThemeProviderContext
} from '@knack/asterisk-react';
import LogRocket from 'logrocket';

import { Route } from '@/enums';
import { type Credentials } from '@/types/auth';
import { useSignInMutation } from '@/hooks/api/mutations/useSignInMutation';
import { isFlagEnabled } from '@/utils/flagsmith';
import { cn } from '@/utils/tailwind';
import { AuthForm } from '@/components/auth-form';
import { GoogleButton } from '@/components/ui/GoogleButton';
import { SignIn2fa } from '@/pages/sign-in/SignIn2fa';

export function SignIn() {
  const [t, { language }] = useTranslation();
  const { isDarkMode } = useContext(ThemeProviderContext);
  const [signInError, setSignInError] = useState<string | React.ReactNode>();
  const [isAccountLocked, setAccountLocked] = useState(false);
  const [searchParams] = useSearchParams();
  const { needs2fa, credentials2fa, ...signIn } = useSignInMutation();

  const isAccountDeleted = Boolean(searchParams.get('accountDeleted'));
  const { search } = useLocation();

  /**
   * Attempts to authorize a user with the credentials in the User object.
   * Will generate an error if the email / password combo don't match, if server-side
   * validation of email / password fails, or if a user gets locked out for too many attempts.
   * @param credentials The info from the Sign-in form. Includes username and password.
   */
  const onSubmit = (credentials: Credentials) => {
    signIn.mutate(credentials, {
      onSuccess: () => {
        sessionStorage.clear();
        const redirect = searchParams.get('redirect');
        if (redirect) {
          window.location.replace(
            `${isFlagEnabled('full_nextgen_access') ? import.meta.env.PUBLIC_BUILDER_NEXT_URL : import.meta.env.PUBLIC_BUILDER_URL}/${redirect}`
          );
        }
      },
      onError: (err: any) => {
        const errorCode = err.response?.data?.errors[0]?.message;
        setAccountLocked(errorCode === 'account_locked');

        const errorMessage =
          !errorCode || errorCode === 'generic_error'
            ? 'errors.generic_error'
            : `components.sign_in.${errorCode}`;

        const submissionError = errorMessage.includes('invalid_sign_in') ? (
          <Trans i18nKey={errorMessage}>
            <a
              href={`${import.meta.env.PUBLIC_DASHBOARD_URL}/sign-up`}
              target="_blank"
              rel="noreferrer"
              className="text-emphasis underline"
            >
              <strong>sign up</strong>
            </a>
          </Trans>
        ) : (
          t(errorMessage)
        );

        setSignInError(submissionError);
      }
    });
  };

  // This is temporary until we are to able to track down the cause of the ‘Something went wrong' error some users are seeing when attempting to log in
  // https://knack.atlassian.net/browse/INFRA-1959
  useEffect(() => {
    LogRocket.log('Sign-in page component mounted');

    return () => {
      LogRocket.log('Sign-in page component unmounted');
    };
  }, []);

  return (
    <main
      className={cn('flex min-h-screen flex-col', {
        'bg-brand-gradient': !isDarkMode
      })}
    >
      <div
        className={cn('mx-auto pb-12 pt-10 text-brand sm:mx-0 sm:pl-15', {
          'text-white': isDarkMode
        })}
      >
        <KnackLogo />
      </div>
      <section className="container flex flex-auto items-center justify-center">
        <Card className="mb-12 flex w-full max-w-[600px] flex-col items-center justify-center">
          {isAccountDeleted && (
            <Banner intent="destructive" className="mb-6 text-center">
              {t('components.account_deleted.title')} {t('components.account_deleted.message')}
            </Banner>
          )}
          <div className={cn('mb-10 h-14 w-16 text-brand', isDarkMode && 'text-white')}>
            <AsteriskLogo />
          </div>

          {!needs2fa ? (
            <>
              <h1 className="mb-10 text-3xl" data-testid="auth-login-header-text">
                {t('components.sign_in.title')}
              </h1>
              <GoogleButton />
              {import.meta.env.PUBLIC_KNACK_CRM_MODE !== 'true' && (
                <>
                  <Divider className="mb-10" text={t('titles.or_divider')} />
                  <AuthForm
                    onSubmit={onSubmit}
                    submissionError={signInError}
                    isAccountLocked={isAccountLocked}
                    className="mb-6"
                    isLoading={signIn.isPending}
                  />
                  <p>
                    {t('components.sign_in.no_account')}{' '}
                    <Link
                      className="font-semibold underline"
                      data-testid="signUpLink"
                      to={{ pathname: `/${Route.SignUp}`, search }}
                    >
                      {t('components.sign_up.action')}
                    </Link>
                  </p>
                </>
              )}
            </>
          ) : (
            <SignIn2fa credentials={credentials2fa} />
          )}
        </Card>
      </section>
      <Helmet>
        <title lang={language}>{`${t('components.sign_in.title')} • ${t(
          'titles.app_name'
        )}`}</title>
      </Helmet>
    </main>
  );
}
