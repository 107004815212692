import { useState, type Dispatch, type SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import {
  HiDocumentChartBar as AnalyticsIcon,
  HiSquare2Stack as DuplicateIcon,
  HiOutlineInformationCircle as InfoIcon,
  HiOutlineEllipsisHorizontal as MoreIcon,
  HiArrowPath as RefreshIcon,
  HiUserMinus as RemoveUserIcon,
  HiCog6Tooth as SettingIcon,
  HiWrench as SettingsIcon,
  HiTrash as TrashIcon,
  HiUsers as UsersIcon,
  HiEye as ViewIcon
} from 'react-icons/hi2';
import { MdDragIndicator as DragIcon } from 'react-icons/md';
import { useLocation } from 'react-router-dom';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import {
  Badge,
  Button,
  Dialog,
  DropdownMenu,
  Spinner,
  Tooltip,
  useToast
} from '@knack/asterisk-react';

import { type KnackApplication } from '@/types/apps';
import { useRefreshRecordsMutation } from '@/hooks/api/mutations/useRefreshRecordsMutation';
import { useAccountQuery } from '@/hooks/api/queries/useAccountQuery';
import { useSession } from '@/hooks/useSession';
import { isFlagEnabled } from '@/utils/flagsmith';
import { formatNumberForStorage } from '@/utils/formatters';
import { safeLogRocketTrack } from '@/utils/logrocket';
import { cn } from '@/utils/tailwind';
import {
  CellTooltip,
  SelectedItemType,
  type ActiveRow,
  type SelectedItem
} from '@/pages/apps/apps-table';
import { type DisablingRestrictions } from '@/pages/apps/AppsPage';

interface AppItemProps {
  app: KnackApplication;
  activeRow: ActiveRow;
  setSelectedItem: Dispatch<SetStateAction<SelectedItem | null>>;
  isSharedAppsTable?: boolean;
  sharedAccountSlug?: string;
  restrictions?: DisablingRestrictions;
}

function AppItem({
  app,
  activeRow,
  setSelectedItem,
  isSharedAppsTable,
  sharedAccountSlug,
  restrictions
}: AppItemProps) {
  const [t] = useTranslation();
  const session = useSession();
  const { search } = useLocation();
  const { data: account } = useAccountQuery();

  const {
    id,
    recordCount,
    storage,
    tasks,
    description,
    name,
    slug,
    isCopying: isThisAppBeingCopied,
    isDeleting = false,
    isDeletingAssets
  } = app;
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const isActive = activeRow === id;
  const showHoverEffect = isActive || isMenuOpen;
  const { refreshRecords } = useRefreshRecordsMutation();
  const { presentToast } = useToast();
  const shouldDisableInteraction = restrictions?.disableInteraction || isDeleting;
  const accountHasReachedAppLimit = () =>
    account?.applications
      ? account?.applications.length >= account?.plan_limits?.applications
      : false;

  const builderUrl = `${isFlagEnabled('full_nextgen_access') ? import.meta.env.PUBLIC_BUILDER_NEXT_URL : import.meta.env.PUBLIC_BUILDER_URL}/${
    isSharedAppsTable ? sharedAccountSlug : session.account.slug
  }/${slug}`;

  const liveAppSuffix = import.meta.env.PUBLIC_LIVE_APP_SUFFIX;

  const liveAppUrl = `//${
    isSharedAppsTable ? sharedAccountSlug : session.account.slug
  }.${liveAppSuffix}/${slug}`;

  const appItemStyle = {
    transform: CSS.Transform.toString(transform),
    transition
  };

  const commonCellClasses = {
    'table-cell px-2 align-middle group-hover:bg-subtle': true,
    'bg-subtle': showHoverEffect
  };

  function refreshRecordsHandler() {
    refreshRecords.mutate(id, {
      onSuccess: () => {
        presentToast({
          title: t('components.apps.refresh_record_success', { appName: name })
        });
      },
      onError: () => {
        presentToast({
          title: t('components.apps.refresh_record_error')
        });
      }
    });
  }

  async function copyToClipboard() {
    try {
      await navigator.clipboard.writeText(`https:${liveAppUrl}`);
      presentToast({
        title: t('components.apps.copying_app_link_success')
      });
    } catch (error) {
      presentToast({
        title: t('components.apps.copying_app_link_error')
      });
    }
  }

  function trackBuilderLinkClickEvent() {
    safeLogRocketTrack('NavigateToBuilder', account?.settings?.hipaa.enabled);
  }

  return (
    <div
      ref={setNodeRef}
      style={appItemStyle}
      className={cn('group relative table-row md:hover:text-default', {
        'z-10 text-default drop-shadow': showHoverEffect,
        'pointer-events-none text-subtle': shouldDisableInteraction || isThisAppBeingCopied
      })}
      data-testid="apps-table-row"
    >
      {!isSharedAppsTable && (
        <div
          data-testid="apps-table-rearrange-icon"
          className={cn(
            'table-cell cursor-grab rounded-l-lg py-4 pr-2 align-middle active:cursor-grabbing active:select-none group-hover:visible md:invisible',
            { 'md:visible': showHoverEffect }
          )}
          {...listeners}
          {...attributes}
        >
          <DragIcon size={24} />
        </div>
      )}
      <div
        className={cn(
          'whitespace-nowrap rounded-l-lg py-4 group-hover:first:rounded-l-lg group-hover:last:rounded-r-lg',
          commonCellClasses
        )}
      >
        <CellTooltip>
          <a
            href={`${builderUrl}${search}`}
            className="hover:text-emphasis"
            aria-label={`Go to the ${name} application builder`}
            tabIndex={shouldDisableInteraction ? -1 : undefined}
            data-testid="apps-table-app-name-field"
            onClick={trackBuilderLinkClickEvent}
          >
            {name}
          </a>
        </CellTooltip>
        {isDeleting && (
          <CellTooltip text={t('components.apps.tooltip_delete')}>
            <Badge
              intent="destructive"
              className="pointer-events-auto cursor-default"
              data-testid="apps-table-app-badge-deleting"
            >
              {t('keywords.deleting')}
            </Badge>
          </CellTooltip>
        )}
        {isThisAppBeingCopied && (
          <CellTooltip text={t('components.apps.copying_app_tooltip')}>
            <Badge
              intent="blue"
              className="pointer-events-auto cursor-default"
              data-testid="apps-table-app-badge-duplicating"
            >
              {t('keywords.duplicating')}
            </Badge>
          </CellTooltip>
        )}
        {isDeletingAssets && (
          <CellTooltip text={t('components.apps.tooltip_delete_assets')}>
            <Spinner />
          </CellTooltip>
        )}
      </div>
      <div
        className={cn('text-right', commonCellClasses)}
        data-testid="apps-table-app-records-count"
      >
        <CellTooltip>{recordCount !== undefined ? recordCount : '-'}</CellTooltip>
      </div>
      <div
        className={cn('whitespace-nowrap text-right', commonCellClasses)}
        data-testid="apps-table-app-storage-count"
      >
        <CellTooltip>{storage !== undefined ? formatNumberForStorage(storage) : '-'}</CellTooltip>
      </div>
      <div className={cn('text-right', commonCellClasses)} data-testid="apps-table-app-tasks-count">
        <CellTooltip>{tasks?.total}</CellTooltip>
      </div>
      <div className={cn(commonCellClasses)} data-testid="apps-table-app-description">
        <CellTooltip>{description}</CellTooltip>
      </div>
      <div className={cn('rounded-r-lg', commonCellClasses)}>
        <div className="flex">
          <CellTooltip isIconButton text={t('components.apps.builder')}>
            <a
              href={`${builderUrl}${search}`}
              aria-label="App builder"
              className="inline-flex h-10 items-center justify-center rounded-md p-2 hover:bg-black/5 focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-400 disabled:pointer-events-none disabled:opacity-50"
              tabIndex={shouldDisableInteraction ? -1 : undefined}
              onClick={trackBuilderLinkClickEvent}
            >
              <SettingsIcon size={24} className="text-gray-500 hover:text-emphasis" />
            </a>
          </CellTooltip>
          <CellTooltip isIconButton text={t('components.apps.live_app')}>
            <a
              href={`${liveAppUrl}${search}`}
              target="_blank"
              rel="noreferrer"
              className="inline-flex h-10 items-center justify-center rounded-md p-2 hover:bg-black/5 focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-400 disabled:pointer-events-none disabled:opacity-50"
              aria-label="Live app"
              tabIndex={shouldDisableInteraction ? -1 : undefined}
            >
              <ViewIcon size={24} className="text-gray-500 hover:text-emphasis" />
            </a>
          </CellTooltip>
          <DropdownMenu onOpenChange={setIsMenuOpen}>
            <DropdownMenu.Trigger
              className="appearance-none"
              asChild
              aria-label="More options"
              onPointerDown={() => setIsMenuOpen(true)}
            >
              <div
                data-feature="more_options_button"
                data-feature-x-offset={20}
                data-feature-y-offset={-4}
                className="rounded"
              >
                <CellTooltip text={t('components.apps.more_options')}>
                  <Button
                    intent="minimal"
                    className={cn('p-2 hover:bg-black/5', {
                      'bg-black/5': isMenuOpen
                    })}
                    disabled={shouldDisableInteraction}
                  >
                    <MoreIcon size={24} className="text-gray-500 hover:text-emphasis" />
                  </Button>
                </CellTooltip>
              </div>
            </DropdownMenu.Trigger>
            <DropdownMenu.Content align="end" data-testid="more-options-modal" className="w-64">
              <DropdownMenu.Item
                onSelect={() => copyToClipboard()}
                data-testid="more-options-copy-live-app-link"
              >
                <DuplicateIcon size={18} className="mr-2" />
                {t('components.apps.copy_live_app')}
              </DropdownMenu.Item>
              {!isSharedAppsTable && (
                <>
                  <DropdownMenu.Item
                    asChild
                    onSelect={() =>
                      setSelectedItem({
                        app,
                        type: SelectedItemType.Settings
                      })
                    }
                  >
                    <Dialog.Trigger asChild>
                      <div
                        className="flex items-center space-x-2"
                        data-testid="more-options-app-settings"
                      >
                        <SettingIcon size={18} className="mr-2" />
                        {t('components.apps.app_settings')}
                      </div>
                    </Dialog.Trigger>
                  </DropdownMenu.Item>
                  <DropdownMenu.Item
                    asChild
                    onSelect={() =>
                      setSelectedItem({
                        app,
                        type: SelectedItemType.ManageBuilders
                      })
                    }
                  >
                    <Dialog.Trigger asChild>
                      <div
                        className="flex items-center space-x-2"
                        data-testid="more-options-manage-builders"
                        data-feature="manage_builders_button"
                        data-feature-x-offset={20}
                        data-feature-y-offset={20}
                      >
                        <UsersIcon size={18} className="mr-2" />
                        {t('components.apps.manage_builders')}
                      </div>
                    </Dialog.Trigger>
                  </DropdownMenu.Item>
                  <DropdownMenu.Item
                    asChild
                    disabled={restrictions?.createApp || accountHasReachedAppLimit()}
                    onSelect={() =>
                      setSelectedItem({
                        app,
                        type: SelectedItemType.Duplicate
                      })
                    }
                  >
                    <Dialog.Trigger asChild>
                      <div
                        className="flex items-center space-x-2"
                        data-testid="more-options-duplicate-app"
                      >
                        <DuplicateIcon size={18} className="mr-2" />
                        {t('components.apps.duplicate_app')}
                      </div>
                    </Dialog.Trigger>
                  </DropdownMenu.Item>
                </>
              )}
              <DropdownMenu.Separator className="mx-4" />
              <DropdownMenu.Item
                className="flex justify-between"
                onSelect={() => refreshRecordsHandler()}
              >
                <div className="flex items-center">
                  <RefreshIcon size={18} className="mr-2" />
                  {t('components.apps.refresh_count')}
                </div>
                <Tooltip>
                  <Tooltip.Trigger>
                    <InfoIcon size={18} />
                  </Tooltip.Trigger>
                  <Tooltip.Content className="max-w-[35ch]">
                    {t('components.apps.refresh_count_tooltip')}
                  </Tooltip.Content>
                </Tooltip>
              </DropdownMenu.Item>
              <DropdownMenu.Item
                asChild
                onSelect={() => {
                  setSelectedItem({
                    app,
                    type: SelectedItemType.TasksQuick
                  });
                }}
              >
                <Dialog.Trigger asChild>
                  <div
                    className="flex items-center space-x-2"
                    data-testid="more-options-task-quick-view"
                  >
                    <AnalyticsIcon size={18} className="mr-2" />
                    {t('components.apps.tasks_quick_view')}
                  </div>
                </Dialog.Trigger>
              </DropdownMenu.Item>
              <DropdownMenu.Separator className="mx-4" />
              {!isSharedAppsTable ? (
                <DropdownMenu.Item
                  asChild
                  className="text-destructive"
                  onSelect={() =>
                    setSelectedItem({
                      app,
                      type: SelectedItemType.Delete
                    })
                  }
                >
                  <Dialog.Trigger asChild>
                    <div
                      className="flex items-center space-x-2"
                      data-testid="more-options-delete-app"
                    >
                      <TrashIcon size={18} className="mr-2" />
                      {t('components.apps.delete_app')}
                    </div>
                  </Dialog.Trigger>
                </DropdownMenu.Item>
              ) : (
                <DropdownMenu.Item
                  asChild
                  className="text-destructive"
                  onSelect={() =>
                    setSelectedItem({
                      app,
                      type: SelectedItemType.DeleteBuilder
                    })
                  }
                >
                  <Dialog.Trigger asChild>
                    <div className="flex items-center space-x-2">
                      <RemoveUserIcon size={18} className="mr-2" />
                      {t('components.apps.remove_my_access')}
                    </div>
                  </Dialog.Trigger>
                </DropdownMenu.Item>
              )}
            </DropdownMenu.Content>
          </DropdownMenu>
        </div>
      </div>
    </div>
  );
}

export { AppItem };
