import { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  HiArrowRight as ArrowRightIcon,
  HiCheckCircle as CheckCircleIcon,
  HiPlayCircle as PlayIcon
} from 'react-icons/hi2';
import { MdOutlineCircle } from 'react-icons/md';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, ThemeProviderContext, useToast } from '@knack/asterisk-react';

import { type KnackApplication } from '@/types/apps';
import { useAppsQuery } from '@/hooks/api/queries/useAppsQuery';
import { useSession } from '@/hooks/useSession';
import { useChecklistsContext } from '@/contexts/ChecklistsContext';
import { cn } from '@/utils/tailwind';
import { SelectAppChecklistModal } from '@/components/checklists/SelectAppChecklistModal';
import { ChecklistType, type ChecklistStep } from '@/components/checklists/types';

export function ChecklistsStep({ step, isLocked }: { step: ChecklistStep; isLocked: boolean }) {
  const [t] = useTranslation();
  const { isDarkMode } = useContext(ThemeProviderContext);
  const navigate = useNavigate();
  const { setHighlightedFeatures } = useChecklistsContext();
  const { presentToast } = useToast();
  const { data: appsData } = useAppsQuery();
  const [shouldShowAppSelectorModal, setShouldShowAppSelectorModal] = useState(false);
  const { search } = useLocation();
  const session = useSession();

  const isCompleted = !!step.completed_at;

  const actionComponent = useMemo(() => {
    if (step.definition.type === 'video' || !!step.definition.video_url) {
      return <PlayIcon className="text-subtle" size={16} />;
    }

    if (
      step.definition.type === ChecklistType.InProduct ||
      step.definition.type === ChecklistType.Documentation
    ) {
      return <ArrowRightIcon className="text-subtle" size={16} />;
    }

    return null;
  }, [step.definition.type]);

  const onStartTour = () => {
    const [area, url, highlight] = step.definition.extra.link!.split('::');

    if (area === 'dashboard') {
      if (highlight) {
        setHighlightedFeatures(highlight.split(','));
      }

      navigate(`/${url}`);
    } else if (area === 'builder') {
      if (appsData.length === 0) {
        presentToast({
          title: t('components.checklists.errors.app_needed'),
          intent: 'destructive'
        });
      } else if (appsData.length > 1) {
        setShouldShowAppSelectorModal(true);
      } else {
        const app = appsData[0] as KnackApplication;

        const builderUrl = `${import.meta.env.PUBLIC_BUILDER_URL}/${
          session.account.slug
        }/${app.slug}`;

        const params = new URLSearchParams(search);
        params.append('checklist_step', step.definition.id);

        window.location.href = `${builderUrl}?${params.toString()}`;
      }
    }
  };

  const stepIcon = useMemo(() => {
    if (isCompleted) {
      return <CheckCircleIcon size={20} className="text-green-400" />;
    }
    return <MdOutlineCircle className="text-green-400" size={18} />;
  }, [isCompleted]);

  return (
    <>
      <button
        type="button"
        className={cn('group flex gap-3 rounded p-2 text-start transition-colors', {
          'items-center': !step.definition.description,
          'bg-default hover:bg-muted': !isCompleted,
          'bg-overlay': isDarkMode && !isCompleted
        })}
        onClick={onStartTour}
        data-step-id={step.definition.id}
      >
        {stepIcon}
        <div className="flex flex-1 flex-col gap-1">
          <div
            className={cn('text-emphasis', {
              'text-subtle': isCompleted,
              'text-opacity-50': isCompleted && !isDarkMode
            })}
          >
            {t(`components.checklists.steps.${step.definition.id}`)}
          </div>
        </div>
        <div
          className={cn('self-center rounded p-1 transition-colors', {
            'group-hover:bg-subtle': !isCompleted && actionComponent,
            'opacity-50': isLocked
          })}
        >
          {isCompleted && !!step.definition.article_url ? (
            <Button intent="link" size="xs" className="text-xs font-normal" asChild>
              <a tabIndex={-1} href={step.definition.article_url} target="_blank" rel="noreferrer">
                {t('components.checklists.step_learn_more')}
              </a>
            </Button>
          ) : (
            actionComponent
          )}
        </div>
      </button>

      {shouldShowAppSelectorModal && (
        <SelectAppChecklistModal
          stepId={step.definition.id}
          open={shouldShowAppSelectorModal}
          setIsOpen={setShouldShowAppSelectorModal}
        />
      )}
    </>
  );
}
