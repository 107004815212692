import { useTranslation } from 'react-i18next';

import { cn } from '@/utils/tailwind';

type ChecklistsProgressProps = {
  section?: string;
  steps: {
    total: number;
    completed: number;
  };
};

export function ChecklistsProgress({ section, steps }: ChecklistsProgressProps) {
  const [t] = useTranslation();
  const progress = Math.round((steps.completed / steps.total) * 100);

  return (
    <div
      className={cn('flex flex-row items-center gap-2 py-1', {
        'gap-3': !!section,
        'max-w-[300px]': !section
      })}
    >
      <div className="text-xs text-subtle">
        {t('components.checklists.completion_steps', {
          completed: steps.completed,
          total: steps.total
        })}
      </div>
      <div id="progress-bar" className="relative my-auto h-2 flex-1 overflow-hidden rounded-full">
        <div className="absolute inset-0 bg-green-100" />
        <div
          className="absolute inset-0 rounded-full bg-green-400 opacity-100 transition-all"
          style={{ width: `${progress}%` }}
        />
      </div>
      <div className="flex items-center justify-between">
        {section && (
          <div className="font-semibold">{t(`components.checklists.sections.${section}`)}</div>
        )}
      </div>
    </div>
  );
}
