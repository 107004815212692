import React, { useMemo } from 'react';
import { Controller, type Control, type FieldErrors } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Label, Select } from '@knack/asterisk-react';

import {
  IssueWithProductReasons,
  IssueWithSupportReasons,
  MovedToCompetitorReasons,
  ReasonsForDeleting
} from '@/enums';
import { cn } from '@/utils/tailwind';
import { FormControl } from '@/components/ui/FormControl';
import { type ExitSurveySchemaType } from './DeleteAccountModal';

interface ExitSurveySelectProps {
  reasonForAccountDeletion: React.Key | null | undefined;
  control: Control<ExitSurveySchemaType>;
  errors: FieldErrors<ExitSurveySchemaType>;
}

interface SurveyItem {
  label: string;
  value: IssueWithProductReasons | IssueWithSupportReasons | MovedToCompetitorReasons;
}

export function ExitSurveySelect({
  reasonForAccountDeletion,
  control,
  errors
}: ExitSurveySelectProps) {
  const [t] = useTranslation();
  const possibleErrors = ['productIssue', 'supportIssue', 'otherCompetitorReason'];
  const errorKey = Object.keys(errors)[0];

  const issueWithProductSelectItems = useMemo<{ label: string; value: IssueWithProductReasons }[]>(
    () => [
      {
        label: t('components.account.delete_modal.issue_with_product.lack_of_design'),
        value: IssueWithProductReasons.LackDesignElements
      },
      {
        label: t('components.account.delete_modal.issue_with_product.too_difficult_to_use'),
        value: IssueWithProductReasons.TooDifficultToUse
      },
      {
        label: t('components.account.delete_modal.issue_with_product.too_slow'),
        value: IssueWithProductReasons.TooSlowLaggy
      },
      {
        label: t('components.account.delete_modal.issue_with_product.bugs_not_fixed'),
        value: IssueWithProductReasons.BugsNotFixed
      },
      {
        label: t('components.account.delete_modal.issue_with_product.missing_features'),
        value: IssueWithProductReasons.MissingFeatures
      }
    ],
    [t]
  );

  const issueWithSupportSelectItems = useMemo<{ label: string; value: IssueWithSupportReasons }[]>(
    () => [
      {
        label: t('components.account.delete_modal.issue_with_support.slow_responses'),
        value: IssueWithSupportReasons.SlowResponses
      },
      {
        label: t('components.account.delete_modal.issue_with_support.never_received_responses'),
        value: IssueWithSupportReasons.NeverReceivedResponse
      },
      {
        label: t('components.account.delete_modal.issue_with_support.issue_was_not_resolved'),
        value: IssueWithSupportReasons.IssueWasNotResolved
      },
      {
        label: t('components.account.delete_modal.issue_with_support.agent_was_rude'),
        value: IssueWithSupportReasons.AgentWasRude
      },
      {
        label: t('components.account.delete_modal.issue_with_support.not_enough_help_resources'),
        value: IssueWithSupportReasons.NotEnoughHelpResources
      }
    ],
    [t]
  );

  const movedToCompetitorsSelectItems = useMemo<
    { label: string; value: MovedToCompetitorReasons }[]
  >(
    () => [
      {
        label: t('components.account.delete_modal.moved_to_other_competitors.price'),
        value: MovedToCompetitorReasons.Price
      },
      {
        label: t(
          'components.account.delete_modal.moved_to_other_competitors.missing_better_features'
        ),
        value: MovedToCompetitorReasons.BetterFeatures
      },
      {
        label: t('components.account.delete_modal.moved_to_other_competitors.referred_by_someone'),
        value: MovedToCompetitorReasons.ReferredBySomeone
      }
    ],
    [t]
  );

  const getSelectLabel = useMemo(() => {
    if (reasonForAccountDeletion === ReasonsForDeleting.Competitor) {
      return t(
        'components.account.delete_modal.moved_to_other_competitors.switch_different_vendor'
      );
    }
    return t('components.account.delete_modal.issue_with_product.please_elaborate');
  }, [reasonForAccountDeletion, t]);

  const selectItemsArray = useMemo(() => {
    if (reasonForAccountDeletion === ReasonsForDeleting.IssueWithProduct) {
      return issueWithProductSelectItems;
    }
    if (reasonForAccountDeletion === ReasonsForDeleting.IssueWithSupport) {
      return issueWithSupportSelectItems;
    }
    if (reasonForAccountDeletion === ReasonsForDeleting.Competitor) {
      return movedToCompetitorsSelectItems;
    }
    return [];
  }, [
    issueWithProductSelectItems,
    issueWithSupportSelectItems,
    movedToCompetitorsSelectItems,
    reasonForAccountDeletion
  ]);

  const getControllerName = useMemo(() => {
    switch (reasonForAccountDeletion) {
      case ReasonsForDeleting.IssueWithProduct:
        return 'productIssue';
      case ReasonsForDeleting.IssueWithSupport:
        return 'supportIssue';
      case ReasonsForDeleting.Competitor:
        return 'otherCompetitorReason';
      default:
        return null;
    }
  }, [reasonForAccountDeletion]);

  return (
    // set the key to force rerender the Select component to reset internal state
    <React.Fragment key={reasonForAccountDeletion}>
      <Label isRequired htmlFor="delete-account-select-component">
        {getSelectLabel}
      </Label>
      {getControllerName && (
        <Controller
          control={control}
          name={getControllerName}
          rules={{ required: true }}
          render={({ field }) => (
            <Select onValueChange={field.onChange} value={field.value}>
              <Select.Trigger
                id="delete-account-select-component"
                data-testid="delete-account-select-component-trigger"
                placeholder={t('components.account.delete_modal.issue_with_product.select_option')}
                className={cn({
                  'text-subtle': !field.value
                })}
              />
              <Select.Content data-testid="delete-account-select-content" position="item-aligned">
                {selectItemsArray.map((item: SurveyItem) => (
                  <Select.Item
                    key={item.value}
                    value={item.value}
                    className="cursor-pointer text-base hover:bg-muted"
                    data-testid={`delete-account-select-item-${item.value}`}
                  >
                    {item.label}
                  </Select.Item>
                ))}
              </Select.Content>
            </Select>
          )}
        />
      )}
      {errors[errorKey] && possibleErrors.includes(errorKey) && (
        <FormControl.Message type="error" className="mt-0">
          {errors[errorKey].message}
        </FormControl.Message>
      )}
    </React.Fragment>
  );
}
