import {
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
  type ColumnDef
} from '@tanstack/react-table';

interface TableProps<T> {
  data: T[];
  columns: ColumnDef<T>[];
}

export function Table<T>({ data, columns }: TableProps<T>) {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: {
      pagination: {
        pageSize: 1000
      }
    }
  });
  // TODO: Add pagination (and maybe sorting) functionality
  return (
    <div className="overflow-auto">
      <table className="w-full table-auto whitespace-nowrap text-left">
        <thead className="border-b border-default align-top">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  colSpan={header.colSpan}
                  className="p-2 font-medium"
                  align={(header.column.columnDef.meta as any)?.align}
                >
                  {header.isPlaceholder ? null : (
                    <>{flexRender(header.column.columnDef.header, header.getContext())}</>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>

        {/* Dummy tbody as vertical spacer */}
        <tbody className="h-4" />

        <tbody className="align-top">
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id} className="group align-middle hover:bg-subtle hover:text-emphasis">
              {row.getVisibleCells().map((cell) => (
                <td
                  key={cell.id}
                  className="px-4 py-1.5 group-hover:first:rounded-l-lg group-hover:last:rounded-r-lg"
                  align={(cell.column.columnDef.meta as any)?.align}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
