import { useLocation, useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';

import { Route } from '@/enums';
import { queryKeys } from '@/hooks/api/queryKeys';

type LogoutResponse = {
  logout: boolean;
};

type LogoutParams = {
  accountId: string;
  userId: string;
  isSharedBuilderOnly?: boolean;
};

async function postLogoutUser({ accountId, userId, isSharedBuilderOnly }: LogoutParams) {
  const url = isSharedBuilderOnly
    ? `/v1/accounts/shared/session/${userId}`
    : `/v1/accounts/${accountId}/session/${userId}`;

  const { data } = await axios.delete<LogoutResponse>(url, {
    withCredentials: true
  });

  return data;
}

const hideIntercom = () => {
  const intercomContainer = document.getElementById('intercom-container');
  if (intercomContainer) {
    intercomContainer.style.display = 'none';
  }

  // use Intercom JS API to hide the launcher and any open windows
  window.Intercom('update', {
    hide_default_launcher: true
  });
  window.Intercom('hide');

  // in some cases segment will try to reload intercom after
  // we have already closed it, so we need to hide it again
  setTimeout(() => {
    window.Intercom('update', {
      hide_default_launcher: true
    });
    window.Intercom('hide');
  }, 2000);
};

export function useLogoutUserMutation() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { search } = useLocation();

  return useMutation({
    mutationFn: postLogoutUser,
    onSuccess: (data: LogoutResponse) => {
      if (data.logout) {
        void queryClient.resetQueries({
          queryKey: [queryKeys.auth.session]
        });

        // Remove query data from the cache, but not inform observers about it
        queryClient.removeQueries();

        hideIntercom();
        window.HubSpotConversations?.widget?.remove();
        navigate({ pathname: `/${Route.SignIn}`, search });
      }
    }
  });
}
