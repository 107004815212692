import { useTranslation } from 'react-i18next';
import { Label } from '@knack/asterisk-react';

import { type Builder as BuilderType } from '@/types/apps';
import { Builder } from './Builder';

interface BuildersListProps {
  builders: BuilderType[] | undefined;
  appId: string;
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
}

export function BuildersList({ builders, appId, setErrorMessage }: BuildersListProps) {
  const [t] = useTranslation();

  return (
    <div className="mt-4 border-t border-default pt-4">
      {builders && builders.length > 0 && (
        <>
          <Label className="font-medium">{t('components.manage_builders.builders')}</Label>
          <div
            className="mt-4 flex flex-col gap-2"
            data-testid="manage-builders-list-shared-builders"
          >
            {builders.map((builder) => {
              if (!builder) return null;
              return (
                <Builder
                  key={builder.id}
                  appId={appId}
                  builder={builder}
                  setErrorMessage={setErrorMessage}
                />
              );
            })}
          </div>
        </>
      )}
    </div>
  );
}
