import React, { useEffect, useMemo } from 'react';
import {
  Controller,
  type Control,
  type FieldErrors,
  type UseFormRegister,
  type UseFormUnregister,
  type UseFormWatch
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Label, RadioGroup, Textarea } from '@knack/asterisk-react';

import { ReasonsForDeleting } from '@/enums';
import { FormControl } from '@/components/ui/FormControl';
import { type ExitSurveySchemaType } from './DeleteAccountModal';
import { ExitSurveyAddiontalInfo } from './ExitSurveyAdditionalInfo';
import { ExitSurveySelect } from './ExitSurveySelect';

interface ExitSurveyProps {
  register: UseFormRegister<ExitSurveySchemaType>;
  control: Control<ExitSurveySchemaType>;
  errors: FieldErrors<ExitSurveySchemaType>;
  watchExitSurveyForm: UseFormWatch<ExitSurveySchemaType>;
  unregister: UseFormUnregister<ExitSurveySchemaType>;
}

export function ExitSurvey({
  register,
  control,
  errors,
  watchExitSurveyForm,
  unregister
}: ExitSurveyProps) {
  const [t] = useTranslation();
  const { productIssue, supportIssue, reasonForAccountDeletion, otherCompetitorReason } =
    watchExitSurveyForm();

  const showFeedbackTextArea =
    reasonForAccountDeletion &&
    ![
      ReasonsForDeleting.IssueWithProduct,
      ReasonsForDeleting.IssueWithSupport,
      ReasonsForDeleting.Competitor,
      ReasonsForDeleting.Other,
      ReasonsForDeleting.NotGoodFit
    ].includes(reasonForAccountDeletion);

  const showExitSurveyAddiontalInfo =
    productIssue ||
    supportIssue ||
    otherCompetitorReason ||
    reasonForAccountDeletion === ReasonsForDeleting.Other ||
    reasonForAccountDeletion === ReasonsForDeleting.NotGoodFit;

  const showExitSurveySelect =
    reasonForAccountDeletion === ReasonsForDeleting.IssueWithProduct ||
    reasonForAccountDeletion === ReasonsForDeleting.IssueWithSupport ||
    reasonForAccountDeletion === ReasonsForDeleting.Competitor;

  const radioItems = useMemo<{ label: string; value: ReasonsForDeleting }[]>(
    () =>
      [
        {
          label: t('components.account.delete_modal.reasons.price'),
          value: ReasonsForDeleting.Price
        },
        {
          label: t('components.account.delete_modal.reasons.issue_with_product'),
          value: ReasonsForDeleting.IssueWithProduct
        },
        {
          label: t('components.account.delete_modal.reasons.issue_with_support'),
          value: ReasonsForDeleting.IssueWithSupport
        },
        {
          label: t('components.account.delete_modal.reasons.project_ended'),
          value: ReasonsForDeleting.ProjectEnded
        },
        {
          label: t('components.account.delete_modal.reasons.job_change'),
          value: ReasonsForDeleting.JobChanged
        },
        {
          label: t('components.account.delete_modal.reasons.competitor'),
          value: ReasonsForDeleting.Competitor
        },
        {
          label: t('components.account.delete_modal.reasons.not_a_good_fit_for_us'),
          value: ReasonsForDeleting.NotGoodFit
        },
        {
          label: t('components.account.delete_modal.reasons.other'),
          value: ReasonsForDeleting.Other
        }
      ].sort(() => Math.random() - 0.5), // Randomize the options, so that the first option is not chosen by default.
    [t]
  );

  useEffect(() => {
    // Unregister fields that are not needed based on the reason for account deletion
    // e.g if the reason is 'Price' then we don't need 'productIssue' if it was previously selected
    unregister([
      'productIssue',
      'issueAdditionalInformation',
      'productIssueMissingFeature',
      'productIssueBugs',
      'supportIssue',
      'otherCompetitor',
      'otherCompetitorReason',
      'directFeedback',
      'otherReason',
      'notGoodFitForUsReason',
      'otherCompetitorMissingFeatures'
    ]);
  }, [reasonForAccountDeletion, unregister]);

  useEffect(() => {
    // Unregister fields that are not needed based on the product issue
    // e.g if user select missing features and fills the text, but change to another option, we reset the form.
    if (productIssue) {
      unregister([
        'issueAdditionalInformation',
        'productIssueMissingFeature',
        'productIssueBugs',
        'directFeedback'
      ]);
    }
    if (supportIssue) {
      unregister(['supportIssueTicketID', 'issueAdditionalInformation', 'directFeedback']);
    }
  }, [productIssue, supportIssue, unregister]);

  return (
    <>
      <div className="flex flex-col gap-2" data-testid="delete-account-dialog-reason">
        <Label className={`${errors.reasonForAccountDeletion && 'text-destructive'}`}>
          {t('components.account.delete_modal.reason_for_leaving')}
          <span className="ml-1 text-brand">*</span>
        </Label>
        <Controller
          control={control}
          name="reasonForAccountDeletion"
          render={({ field }) => (
            <RadioGroup onValueChange={field.onChange} value={field.value}>
              {radioItems?.map((item) => (
                <React.Fragment key={item.value}>
                  <RadioGroup.Container>
                    <RadioGroup.Item
                      value={item.value}
                      id={item.value}
                      data-testid={`delete-account-reason-item-${item.value}`}
                    />
                    <Label htmlFor={item.value}>{item.label}</Label>
                  </RadioGroup.Container>
                </React.Fragment>
              ))}
            </RadioGroup>
          )}
        />
        {errors.reasonForAccountDeletion && (
          <FormControl.Message type="error" className="mt-0">
            {errors.reasonForAccountDeletion.message}
          </FormControl.Message>
        )}
      </div>
      <div className="flex flex-col gap-2" data-testid="delete-account-dialog-feedback">
        {showExitSurveySelect && (
          <ExitSurveySelect
            reasonForAccountDeletion={reasonForAccountDeletion}
            control={control}
            errors={errors}
          />
        )}
        {showExitSurveyAddiontalInfo && (
          <ExitSurveyAddiontalInfo
            productIssue={productIssue}
            supportIssue={supportIssue}
            otherCompetitorReason={otherCompetitorReason}
            register={register}
            unregister={unregister}
            reasonForAccountDeletion={reasonForAccountDeletion}
            errors={errors}
          />
        )}
        {showFeedbackTextArea && (
          <>
            <Label htmlFor="exit-survey-feedback">
              {t('components.account.delete_modal.feedback')}
            </Label>
            <Textarea
              id="exit-survey-feedback"
              placeholder={t('components.account.delete_modal.feedback_placeholder')}
              {...register('directFeedback')}
            />
          </>
        )}
      </div>
    </>
  );
}
