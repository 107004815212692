import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, ProgressRing } from '@knack/asterisk-react';

import { Route } from '@/enums';
import { useAccountQuery } from '@/hooks/api/queries/useAccountQuery';
import { TRIAL_LENGTH_DAYS } from '@/utils/constants';
import { cn } from '@/utils/tailwind';
import { getHasTrialExpired, getTrialDaysLeft } from '@/utils/trials';

interface TrialCountdownWidgetProps {
  className?: string;
}

export function TrialCountdownWidget({ className = '' }: TrialCountdownWidgetProps) {
  const [t] = useTranslation();
  const { data: account } = useAccountQuery();
  const navigate = useNavigate();
  const { search } = useLocation();

  const isTrial = account?.product_plan?.id === 'trial';
  if (!isTrial) {
    return null;
  }

  let trialDaysLeft = 0;
  let hasTrialExpired = true;
  if (account) {
    hasTrialExpired = getHasTrialExpired(account.beta_deadline);
    trialDaysLeft = hasTrialExpired ? 0 : getTrialDaysLeft(account.beta_deadline);
  }

  const percentage = (trialDaysLeft * 100) / TRIAL_LENGTH_DAYS;
  const widgetTitle = t(
    hasTrialExpired ? 'components.trial_widget.trial_ended' : 'components.trial_widget.free_trial'
  );

  return (
    <div className={cn('flex flex-col gap-2', className)}>
      <div className="flex items-center gap-3">
        <div>
          <ProgressRing percentage={percentage} strokeWidth={8} width={60}>
            <ProgressRing.Text>{trialDaysLeft.toString()}</ProgressRing.Text>
          </ProgressRing>
        </div>
        <div>
          <span
            data-testid="trial-countdown-widget-title"
            className="block text-base font-semibold"
          >
            {widgetTitle}
          </span>
          <span data-testid="trial-countdown-widget-days-remaining" className="block text-subtle">
            {t('components.trial_widget.days_remaining', {
              count: trialDaysLeft
            })}
          </span>
        </div>
      </div>
      <Button
        data-testid="trial-countdown-widget-upgrade-button"
        intent="secondary"
        className="w-full"
        onClick={() =>
          navigate({
            pathname: `/${Route.Settings}/${Route.Billing}/${Route.Plans}`,
            search
          })
        }
      >
        {t('actions.upgrade')}
      </Button>
    </div>
  );
}
