import { useEffect } from 'react';
import { useForm, type SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MdLockClock } from 'react-icons/md';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { Banner, Button, Input, InputPassword } from '@knack/asterisk-react';
import LogRocket from 'logrocket';
import { z } from 'zod';

import { Route } from '@/enums';
import { type Credentials } from '@/types/auth';
import { cn } from '@/utils/tailwind';
import { zodEmailRegex } from '@/utils/zod';
import { FormControl } from '@/components/ui/FormControl';

interface Props {
  onSubmit: (data: Credentials) => void;
  className?: string;
  submissionError?: string | React.ReactNode;
  isAccountLocked?: boolean;
  isSignUp?: boolean;
  isLoading?: boolean;
}

export function AuthForm({
  onSubmit,
  className = '',
  submissionError = '',
  isAccountLocked = false,
  isSignUp = false,
  isLoading = false
}: Props) {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const { search } = useLocation();

  let authFormSchema = z.object({
    email: z
      .string()
      .min(1, t('components.auth.email_required'))
      .regex(zodEmailRegex, t('components.auth.email_invalid')),
    password: z.string().min(1, t('components.auth.password_required'))
  });

  if (isSignUp) {
    authFormSchema = authFormSchema.merge(
      z.object({
        password: z
          .string()
          .min(1, t('components.auth.password_required'))
          .min(8, t('components.auth.password_min_length_error'))
      })
    );
  }

  type AuthFormSchemaType = z.infer<typeof authFormSchema>;

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors }
  } = useForm<AuthFormSchemaType>({
    resolver: zodResolver(authFormSchema)
  });

  const onSubmitHandler: SubmitHandler<AuthFormSchemaType> = (data) => {
    onSubmit(data); // Call onSubmit passed down as prop from consumer
  };

  // This is temporary until we are to able to track down the cause of the ‘Something went wrong' error some users are seeing when attempting to log in
  // https://knack.atlassian.net/browse/INFRA-1959
  useEffect(() => {
    LogRocket.log('AuthForm component mounted');

    return () => {
      LogRocket.log('AuthForm component unmounted');
    };
  }, []);

  return (
    <form
      onSubmit={handleSubmit(onSubmitHandler)}
      className={cn(['w-full text-left', className])}
      noValidate // This disables Chrome's native form validation on the email field
    >
      {submissionError && (
        <Banner
          className="mb-6"
          intent="destructive"
          type="block"
          icon={isAccountLocked ? MdLockClock : undefined}
          title={isAccountLocked ? t('components.sign_in.too_many_failed') : undefined}
          actionLabel={isAccountLocked ? t('components.sign_in.reset_password') : undefined}
          onAction={
            isAccountLocked
              ? () =>
                  navigate({
                    pathname: `/${Route.ForgotPassword}`,
                    search
                  })
              : undefined
          }
        >
          <Banner.Message>{submissionError}</Banner.Message>
        </Banner>
      )}
      <FormControl>
        <FormControl.Label htmlFor="email" intent={errors.email && 'destructive'}>
          {t('attributes.user.email')}
        </FormControl.Label>
        <Input
          title="Email"
          id="email"
          data-testid="auth-login-email-field"
          type="email"
          disabled={isLoading}
          placeholder={t('attributes.user.email')}
          intent={errors.email && 'destructive'}
          {...register('email')}
        />
        {errors.email && (
          <FormControl.Message type="error">{errors.email.message}</FormControl.Message>
        )}
      </FormControl>
      <FormControl className="mt-4">
        <FormControl.Label htmlFor="password" intent={errors.password && 'destructive'}>
          {t('attributes.user.password')}
        </FormControl.Label>
        <InputPassword
          data-private
          title="Password"
          id="password"
          data-testid="auth-login-password-field"
          disabled={isLoading}
          isEmpty={watch('password') === ''}
          hasError={!!errors.password}
          placeholder={t(
            isSignUp ? 'components.auth.new_password_placeholder' : 'attributes.user.password'
          )}
          {...register('password')}
        />
        {errors.password && (
          <FormControl.Message type="error">{errors.password.message}</FormControl.Message>
        )}
      </FormControl>
      {!isSignUp && (
        <Link
          to={{ pathname: `/${Route.ForgotPassword}`, search }}
          className="mt-4 block underline"
          data-testid="auth-login-forgot-password-button"
        >
          {t('components.sign_in.forgot_password')}
        </Link>
      )}
      <Button
        id="submit"
        data-testid="auth-login-continue-button"
        type="submit"
        isLoading={isLoading}
        className="mt-8 h-12 w-full"
      >
        {isSignUp ? t('components.account.create_account') : t('actions.continue')}
      </Button>
    </form>
  );
}
