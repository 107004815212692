import { RudderAnalytics, type ApiObject, type IdentifyTraits } from '@rudderstack/analytics-js';

export const isRudderStackEnabled = () =>
  !!import.meta.env.PUBLIC_RUDDERSTACK_WRITE_KEY &&
  !!import.meta.env.PUBLIC_RUDDERSTACK_DATA_PLANE_URL;

// We use this class to superset some of the RudderStack functions and, for example, allow promises
class RudderAnalyticsInstance {
  private readonly analytics: RudderAnalytics | null =
    RudderAnalyticsInstance.instantiateAnalytics();

  private static instantiateAnalytics() {
    if (!isRudderStackEnabled()) {
      return null;
    }

    const rudderAnalytics = new RudderAnalytics();

    rudderAnalytics.load(
      import.meta.env.PUBLIC_RUDDERSTACK_WRITE_KEY,
      import.meta.env.PUBLIC_RUDDERSTACK_DATA_PLANE_URL
    );

    return rudderAnalytics;
  }

  public async identify(userId: string, traits: IdentifyTraits | null = null) {
    return new Promise<void>((resolve) => {
      if (!this.analytics) {
        resolve();

        return;
      }

      this.analytics.identify(userId, traits, () => resolve());
    });
  }

  public async group(groupId: string, traits: IdentifyTraits | null = null) {
    return new Promise<void>((resolve) => {
      if (!this.analytics) {
        resolve();

        return;
      }

      this.analytics.group(groupId, traits, () => resolve());
    });
  }

  public async page() {
    return new Promise<void>((resolve) => {
      if (!this.analytics) {
        resolve();

        return;
      }

      this.analytics.page(() => resolve());
    });
  }

  public async track(event: string, properties: ApiObject | null) {
    return new Promise<void>((resolve) => {
      if (!this.analytics) {
        resolve();

        return;
      }

      this.analytics.track(event, properties, () => resolve());
    });
  }

  public reset() {
    return this.analytics?.reset();
  }
}

export const rudderStackAnalytics = new RudderAnalyticsInstance();
