import { type FieldErrors, type UseFormRegister, type UseFormUnregister } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Input, Label, Textarea } from '@knack/asterisk-react';

import {
  IssueWithProductReasons,
  IssueWithSupportReasons,
  MovedToCompetitorReasons,
  ReasonsForDeleting
} from '@/enums';
import { FormControl } from '@/components/ui/FormControl';
import { type ExitSurveySchemaType } from './DeleteAccountModal';

interface ExitSurveyAdditionalInfoProps {
  productIssue?: IssueWithProductReasons;
  register: UseFormRegister<ExitSurveySchemaType>;
  unregister: UseFormUnregister<ExitSurveySchemaType>;
  supportIssue?: IssueWithSupportReasons;
  otherCompetitorReason?: MovedToCompetitorReasons;
  reasonForAccountDeletion: ReasonsForDeleting;
  errors: FieldErrors<ExitSurveySchemaType>;
}

export function ExitSurveyAddiontalInfo({
  productIssue,
  register,
  supportIssue,
  otherCompetitorReason,
  reasonForAccountDeletion,
  errors
}: ExitSurveyAdditionalInfoProps) {
  const [t] = useTranslation();
  const selectedIssue = productIssue || supportIssue || otherCompetitorReason;
  const errorKey = Object.keys(errors)[0];

  const supportTickedIDIssues = [
    IssueWithSupportReasons.SlowResponses,
    IssueWithSupportReasons.NeverReceivedResponse,
    IssueWithSupportReasons.IssueWasNotResolved,
    IssueWithSupportReasons.AgentWasRude
  ];
  const isInput = () => {
    const optionsArray = [
      IssueWithProductReasons.BugsNotFixed,
      ...supportTickedIDIssues,
      ...Object.values(MovedToCompetitorReasons)
    ];
    return selectedIssue && optionsArray.includes(selectedIssue);
  };

  const getAdditionalInfoLabel = () => {
    if (productIssue === IssueWithProductReasons.MissingFeatures) {
      return t('components.account.delete_modal.issue_with_product.what_features');
    }
    if (productIssue === IssueWithProductReasons.BugsNotFixed) {
      return t('components.account.delete_modal.issue_with_product.enter_bug_ticket');
    }
    if (supportIssue && supportTickedIDIssues.includes(supportIssue)) {
      return t('components.account.delete_modal.issue_with_support.enter_ticket_number');
    }
    if (otherCompetitorReason) {
      return t('components.account.delete_modal.moved_to_other_competitors.which_competitor');
    }
    return t('components.account.delete_modal.can_you_provide_information');
  };

  const getAdditionalInfoPlaceholder = () => {
    if (productIssue === IssueWithProductReasons.MissingFeatures) {
      return t('components.account.delete_modal.issue_with_product.enter_missing_features');
    }
    if (productIssue === IssueWithProductReasons.BugsNotFixed) {
      return t('components.account.delete_modal.issue_with_product.enter_bug_ticket_placeholder');
    }
    if (supportIssue && supportTickedIDIssues.includes(supportIssue)) {
      return t('components.account.delete_modal.issue_with_support.enter_ticket_placeholder');
    }
    if (otherCompetitorReason) {
      return t(
        'components.account.delete_modal.moved_to_other_competitors.which_competitor_placeholder'
      );
    }

    return t('components.account.delete_modal.provide_additional_information');
  };

  const isRequired =
    productIssue === IssueWithProductReasons.MissingFeatures ||
    Boolean(otherCompetitorReason) ||
    reasonForAccountDeletion === ReasonsForDeleting.Other ||
    reasonForAccountDeletion === ReasonsForDeleting.NotGoodFit;

  const fieldToRegister = () => {
    const options = {
      required: isRequired
    };

    if (productIssue === IssueWithProductReasons.MissingFeatures) {
      return { ...register('productIssueMissingFeature', options) };
    }
    if (productIssue === IssueWithProductReasons.BugsNotFixed) {
      return { ...register('productIssueBugs', options) };
    }
    if (supportIssue && supportTickedIDIssues.includes(supportIssue)) {
      return { ...register('supportIssueTicketID', options) };
    }
    if (otherCompetitorReason) {
      return { ...register('otherCompetitor', options) };
    }
    if (reasonForAccountDeletion === 'other') {
      return { ...register('otherReason', options) };
    }
    if (reasonForAccountDeletion === 'not-a-good-fit-for-us') {
      return { ...register('notGoodFitForUsReason', options) };
    }

    return { ...register('issueAdditionalInformation', options) };
  };

  return (
    <>
      {otherCompetitorReason === MovedToCompetitorReasons.BetterFeatures && (
        <>
          <Label isRequired className="mt-4" htmlFor="delete-modal-competitor-features-input">
            {t('components.account.delete_modal.moved_to_other_competitors.what_features')}
          </Label>
          <Input
            id="delete-modal-competitor-features-input"
            placeholder={t(
              'components.account.delete_modal.moved_to_other_competitors.enter_features'
            )}
            {...register('otherCompetitorMissingFeatures', { required: true })}
          />
          <FormControl.Message type="error" className="mt-0">
            {errors.otherCompetitorMissingFeatures?.message}
          </FormControl.Message>
        </>
      )}
      <Label isRequired={isRequired} htmlFor="delete-modal-additional-info" className="mt-4">
        {getAdditionalInfoLabel()}
      </Label>
      {isInput() ? (
        <Input
          id="delete-modal-additional-info"
          data-testid="delete-modal-additional-info-input"
          placeholder={getAdditionalInfoPlaceholder()}
          {...fieldToRegister()}
        />
      ) : (
        <Textarea
          id="delete-modal-additional-info"
          data-testid="delete-modal-additional-info-textarea"
          placeholder={getAdditionalInfoPlaceholder()}
          {...fieldToRegister()}
        />
      )}
      {errors[errorKey] && (
        <FormControl.Message type="error" className="mt-0">
          {errors[errorKey].message}
        </FormControl.Message>
      )}
    </>
  );
}
