import { Trans, useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import { Route } from '@/enums';

export function TermsAndPrivacy() {
  const [t] = useTranslation();
  const { search } = useLocation();

  return (
    <div className="flex flex-col gap-8 text-center">
      <p>
        <Trans i18nKey="components.sign_up.tocs">
          <a
            href="https://www.knack.com/terms-of-service"
            target="_blank"
            rel="noreferrer"
            className="underline"
          >
            {t('components.sign_up.terms')}
          </a>
          <a
            href="https://www.knack.com/privacy"
            target="_blank"
            rel="noreferrer"
            className="underline"
          >
            {t('components.sign_up.privacy')}
          </a>
        </Trans>
      </p>

      <p>
        {t('components.sign_up.have_account')}{' '}
        <Link className="font-semibold underline" to={{ pathname: `/${Route.SignIn}`, search }}>
          {t('components.sign_in.action')}
        </Link>
      </p>
    </div>
  );
}
