import { Trans, useTranslation } from 'react-i18next';
import { Banner, Input, RadioCardGroup, Skeleton } from '@knack/asterisk-react';

import { type SampleApp } from '@/types/apps';

type TemplateGallerySectionProps = {
  sampleApps: SampleApp[];
  selectedTab: string;
  search: string;
  handleSearchOnChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isLoading: boolean;
  error: string | null;
  setSelectedSample: (sample: string) => void;
  selectedSample: string;
  isSetupWizard?: boolean;
};

export function TemplateGallerySection({
  sampleApps,
  selectedTab,
  search,
  handleSearchOnChange,
  isLoading,
  error,
  setSelectedSample,
  selectedSample,
  isSetupWizard = false
}: TemplateGallerySectionProps) {
  const { t } = useTranslation();

  return (
    <>
      {error && (
        <Banner intent="destructive" data-testid="sample-apps-error">
          <Banner.Message>{t('errors.generic_error')}</Banner.Message>
        </Banner>
      )}
      {!isSetupWizard && (
        <Input
          value={search}
          onChange={handleSearchOnChange}
          placeholder={t('components.create_app.sample_search')}
          data-testid="sample-apps-search-input"
        />
      )}

      {selectedTab === 'all' && search && sampleApps.length === 0 && (
        <div className="flex size-full flex-col items-center justify-center gap-6">
          <div className="text-5xl">🤔</div>
          <div className="text-emphasis">
            <Trans i18nKey="components.create_app.sample_search_no_results" values={{ search }}>
              <span className="font-semibold">search query</span>
            </Trans>
          </div>
        </div>
      )}

      {isLoading ? (
        <div className="grid grid-cols-1 gap-6 md:grid-cols-2" data-testid="sample-apps-skeleton">
          {Array.from(new Array(8), (item, i) => (
            <Skeleton key={i} className="h-36" />
          ))}
        </div>
      ) : (
        <RadioCardGroup
          className="grid grid-cols-1 gap-6 md:grid-cols-2"
          onValueChange={setSelectedSample}
          value={selectedSample}
          data-testid="sample-apps-list"
        >
          {sampleApps.map((sample: SampleApp) => (
            <RadioCardGroup.Card
              contentClassName="pointer-events-auto p-0"
              key={sample.id}
              value={sample.id}
              width="100%"
              shouldHideCircleIcon={isSetupWizard}
            >
              <RadioCardGroup.Content title={sample.title} description={sample.description} />

              <a
                onClick={(e) => e.stopPropagation()}
                className="mt-2 w-fit underline underline-offset-[3px]"
                href={sample.link}
                rel="noreferrer"
                target="_blank"
              >
                {t('components.create_app.more_info')}
              </a>
            </RadioCardGroup.Card>
          ))}
        </RadioCardGroup>
      )}
    </>
  );
}
