import { type FormState, type UseFormGetValues, type UseFormRegisterReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Banner, Button, Dialog, InputPassword } from '@knack/asterisk-react';

import { useSession } from '@/hooks/useSession';
import { DashboardDialogContent } from '@/components/ui/DashboardDialogContent';
import { FormControl } from '@/components/ui/FormControl';

interface FormValues {
  firstName: string;
  lastName: string;
  email: string;
  password?: string;
}

interface AccountInfoFormValues {
  name: string;
  slug: string;
  settings: {
    flows: {
      timezone: string;
      language: string;
    };
  };
}

export function ConfirmPasswordModal({
  register,
  handleSubmit,
  accountOwnerInfoFormState,
  showPasswordDialog,
  getAccountInfoFormValues,
  getAccountOwnerInfoFormValues,
  isGoogleUser
}: {
  register: UseFormRegisterReturn<'password'>;
  isGoogleUser: boolean;
  handleSubmit: () => void;
  accountOwnerInfoFormState: FormState<FormValues>;
  showPasswordDialog: boolean;
  getAccountInfoFormValues: UseFormGetValues<AccountInfoFormValues>;
  getAccountOwnerInfoFormValues: UseFormGetValues<FormValues>;
}) {
  const [t] = useTranslation();
  const session = useSession();

  const isChangingSlug = session?.account?.slug !== getAccountInfoFormValues().slug;
  const isChangingEmail = session?.user?.email !== getAccountOwnerInfoFormValues().email;

  const Footer = (
    <Dialog.Footer>
      <Dialog.Close asChild>
        <Button intent="minimal" data-testid="account-overview-dialog-confirm-new-pwd-cancel-btn">
          {t('actions.cancel')}
        </Button>
      </Dialog.Close>
      {isChangingEmail && (
        <Button
          type="submit"
          form="account-form"
          data-testid="account-overview-dialog-confirm-new-pwd-save-btn"
        >
          {isChangingSlug && isChangingEmail ? t('actions.save_all_changes') : t('actions.save')}
        </Button>
      )}
      {isChangingSlug && !isChangingEmail && (
        <Button
          onClick={handleSubmit}
          data-testid="account-overview-dialog-confirm-new-pwd-save-btn"
        >
          {t('actions.save')}
        </Button>
      )}
    </Dialog.Footer>
  );

  return (
    <DashboardDialogContent
      headerTitle={t('components.profile.confirm_password_modal.title')}
      headerDescription={
        isChangingEmail ? t('components.profile.confirm_password_modal.description') : undefined
      }
      footer={Footer}
      data-testid="account-overview-dialog-confirm-new-pwd"
    >
      <div className="flex flex-col">
        {isChangingEmail && (
          <>
            {isChangingSlug && (
              <p className="mb-2 font-medium">
                {t('components.profile.confirm_password_modal.account_email')}
              </p>
            )}
            <Banner intent="warning" className="mb-6">
              {isGoogleUser
                ? t('components.profile.confirm_password_modal.change_email_disconnect_google')
                : t('components.profile.confirm_password_modal.change_email_banner')}
            </Banner>
          </>
        )}
        {isChangingSlug && (
          <>
            {isChangingEmail && (
              <p className="mb-2 font-medium">
                {t('components.profile.confirm_password_modal.site_url')}
              </p>
            )}
            <Banner intent="warning" className="mb-2">
              {t('components.profile.confirm_password_modal.change_slug_text')}
            </Banner>
          </>
        )}
        {isChangingEmail && (
          <form id="account-form" onSubmit={handleSubmit}>
            <FormControl className="mb-6 mt-4">
              <FormControl.Label htmlFor="profile-password" isRequired>
                {t('components.profile.password')}
              </FormControl.Label>
              <InputPassword
                disabled={!showPasswordDialog}
                data-private
                id="profile-password"
                title={t('components.profile.password')}
                size="sm"
                {...register}
              />
              {accountOwnerInfoFormState.errors.password && (
                <FormControl.Message type="error">
                  {accountOwnerInfoFormState.errors.password.message}
                </FormControl.Message>
              )}
            </FormControl>
          </form>
        )}
      </div>
    </DashboardDialogContent>
  );
}
