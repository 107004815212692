import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Button, Input, Label, Select } from '@knack/asterisk-react';

import { useSession } from '@/hooks/useSession';
import { shouldSendAnalytics } from '@/utils/analytics';
import { rudderStackAnalytics } from '@/utils/rudderstack';
import { BASE_IMAGE_URL, SETUP_WIZARD_STEPS } from '@/pages/apps/create-app/constants';

interface StepSelectIndustryProps {
  setCurrentStep: (step: string) => void;
  selectedIndustry: string | undefined;
  setSelectedIndustry: (value: string | undefined) => void;
  selectedUseCase: string | undefined;
  setSelectedUseCase: (value: string | undefined) => void;
  defaultPathForTranslation: string;
  getTitleFromFilter: (key: string) => string;
  industries: string[];
  useCases: string[];
  isLoading: boolean;
  otherUseCaseDetails: string;
  setOtherUseCaseDetails: (value: string) => void;
}

export function StepSelectIndustry({
  setCurrentStep,
  selectedIndustry,
  setSelectedIndustry,
  selectedUseCase,
  setSelectedUseCase,
  defaultPathForTranslation,
  getTitleFromFilter,
  industries,
  useCases,
  isLoading,
  otherUseCaseDetails,
  setOtherUseCaseDetails
}: StepSelectIndustryProps) {
  const [t] = useTranslation();
  const session = useSession();
  const [hasCustomUseCase, setHasCustomUseCase] = useState(false);
  return (
    <div className="relative mb-8 h-screen overflow-y-auto" data-testid="step-select-industry">
      <div className="grid size-full grid-cols-1 items-center gap-6 p-4 sm:grid-cols-2">
        <div className="col-span-1 flex justify-center">
          <img
            src={`${BASE_IMAGE_URL}step1-illustration.svg`}
            alt={t(`${defaultPathForTranslation}.step_1.title`)}
          />
        </div>
        <div className="col-span-1 flex flex-col justify-center space-y-4">
          <p className="flex items-center gap-2">
            <Trans i18nKey={`${defaultPathForTranslation}.step_1.industry`}>
              <Select
                data-testid="industry-selector"
                onValueChange={(value) => {
                  setSelectedIndustry(value);
                  setOtherUseCaseDetails('');
                }}
                value={selectedIndustry}
              >
                <Select.Trigger
                  size="lg"
                  placeholder={t(`${defaultPathForTranslation}.step_1.select_industry`)}
                />
                <Select.Content className="min-w-[200px]">
                  {industries.map((industry) => (
                    <Select.Item
                      key={industry}
                      value={industry}
                      data-testid={`industry-${industry}`}
                    >
                      {getTitleFromFilter(industry)}
                    </Select.Item>
                  ))}
                </Select.Content>
              </Select>
            </Trans>
          </p>
          <p className="text-body-2 flex items-center gap-2 text-default">
            <Trans i18nKey={`${defaultPathForTranslation}.step_1.use_case`}>
              <Select
                data-testid="use-case-selector"
                onValueChange={(value) => {
                  setSelectedUseCase(value);
                  if (value !== 'other') {
                    setOtherUseCaseDetails('');
                    setHasCustomUseCase(false);
                  }
                }}
                value={selectedUseCase}
                disabled={!selectedIndustry}
              >
                <Select.Trigger
                  size="lg"
                  placeholder={t(`${defaultPathForTranslation}.step_1.select_use_case`)}
                />
                <Select.Content className="min-w-[200px]">
                  {useCases.map((useCase) => (
                    <Select.Item key={useCase} value={useCase} data-testid={`use-case-${useCase}`}>
                      {getTitleFromFilter(useCase)}
                    </Select.Item>
                  ))}
                </Select.Content>
              </Select>
            </Trans>
          </p>
          {selectedUseCase === 'other' && (
            <Input.Container className="mt-4">
              <Label className="text-sm" data-testid="other-use-case-label">
                {t(`${defaultPathForTranslation}.step_1.use_case_input`)}
              </Label>
              <Input
                className="my-2 max-w-[500px]"
                data-testid="other-use-case-input"
                id="other-use-case-input"
                value={selectedUseCase === 'other' ? otherUseCaseDetails : ''}
                title={t(`${defaultPathForTranslation}.step_1.use_case_input`)}
                onChange={(e) => {
                  setOtherUseCaseDetails(e.target.value);
                  setHasCustomUseCase(true);
                }}
              />
              {hasCustomUseCase && otherUseCaseDetails.trim().length === 0 && (
                <span className="text-sm text-red-500" data-testid="use-case-error">
                  {t(`${defaultPathForTranslation}.step_1.use_case_input_error`)}
                </span>
              )}
            </Input.Container>
          )}
        </div>
      </div>
      <div className="fixed bottom-0 left-0 flex h-16 w-full items-center justify-end bg-base px-7 shadow-sm">
        <div className="mr-4 flex items-center">
          <Button
            data-testid="next-button"
            disabled={
              !selectedIndustry ||
              !selectedUseCase ||
              isLoading ||
              (selectedUseCase === 'other' && otherUseCaseDetails.trim().length === 0)
            }
            type="button"
            onClick={() => {
              if (shouldSendAnalytics(session.user.email)) {
                void window.analytics?.track('Setup Wizard: Industry and Use Case sent', {
                  groupId: session.account.id,
                  industry: selectedIndustry,
                  useCase: selectedUseCase
                });
                void rudderStackAnalytics.track('Setup Wizard: Industry and Use Case sent', {
                  groupId: session.account.id,
                  industry: selectedIndustry,
                  useCase: selectedUseCase
                });
              }
              setCurrentStep(SETUP_WIZARD_STEPS[1]);
            }}
          >
            {t('actions.next')}
          </Button>
        </div>
      </div>
    </div>
  );
}
