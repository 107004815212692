import React, { type Dispatch, type SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Dialog } from '@knack/asterisk-react';

type RestrictionModalProps = {
  open: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  title: string;
  message: React.ReactNode;
  buttonText?: string;
  onButtonClick?: React.MouseEventHandler<HTMLButtonElement>;
};

export function RestrictionModal({
  open,
  setIsOpen,
  title,
  message,
  buttonText,
  onButtonClick
}: RestrictionModalProps) {
  const [t] = useTranslation();

  return (
    <Dialog open={open} onOpenChange={setIsOpen}>
      <Dialog.Content className="bg-default">
        <Dialog.MainContent>
          <Dialog.Header className="mb-6 text-lg font-medium text-emphasis">{title}</Dialog.Header>
          <div>{message}</div>
        </Dialog.MainContent>
        <Dialog.Footer className="gap-2 pt-2">
          <Button intent="minimal" onClick={() => setIsOpen(false)}>
            {t('actions.close')}
          </Button>
          {buttonText && <Button onClick={onButtonClick}>{buttonText}</Button>}
        </Dialog.Footer>
      </Dialog.Content>
    </Dialog>
  );
}
