import React, { forwardRef } from 'react';
import { Label as AsteriskLabel } from '@knack/asterisk-react';

import { cn } from '@/utils/tailwind';

export type FormControlProps = React.HTMLAttributes<HTMLFieldSetElement>;

interface LabelProps extends React.LabelHTMLAttributes<HTMLLabelElement> {
  htmlFor: string;
  intent?: 'destructive' | 'success' | 'warning';
  isRequired?: boolean;
}

interface MessageProps extends React.HTMLAttributes<HTMLDivElement> {
  type?: 'error' | 'success' | 'warning' | 'default';
}

function FormControl(
  { children, className = '', ...props }: FormControlProps,
  ref: React.ForwardedRef<HTMLFieldSetElement>
) {
  return (
    <fieldset className={cn(['relative', className])} ref={ref} {...props}>
      {children}
    </fieldset>
  );
}

function Label({ htmlFor, children, className = '', isRequired = false, ...props }: LabelProps) {
  const classes = ['mb-2 inline-block', className];

  return (
    <AsteriskLabel htmlFor={htmlFor} className={cn(classes)} isRequired={isRequired} {...props}>
      {children}
    </AsteriskLabel>
  );
}

function Message({ children, className = '', type = 'default', ...props }: MessageProps) {
  const messageClasses = ['mt-2', className];

  if (type === 'error') messageClasses.push('text-destructive');
  else if (type === 'success') messageClasses.push('text-green-500');
  else if (type === 'warning') messageClasses.push('text-yellow-500');

  return (
    <p className={cn(messageClasses)} {...props}>
      {children}
    </p>
  );
}

const FormControlWithRef = forwardRef<HTMLFieldSetElement, FormControlProps>(FormControl);

const CompoundFormControl = Object.assign(FormControlWithRef, {
  Label,
  Message
});

Label.displayName = 'FormControl.Label';
Message.displayName = 'FormControl.Message';

export { CompoundFormControl as FormControl };
