import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsStars as AiStarsIcon } from 'react-icons/bs';
import {
  HiBeaker as BeakerIcon,
  HiExclamationCircle as ExclamationIcon,
  HiArrowUpTray as ImportIcon
} from 'react-icons/hi2';
import { SiGooglesheets as GoogleSheetsIcon } from 'react-icons/si';
import { useLocation, useNavigate } from 'react-router-dom';
import { Banner, Button, Dialog } from '@knack/asterisk-react';

import { useAppMutation } from '@/hooks/api/mutations/useAppMutation';
import { useSession } from '@/hooks/useSession';
import { getErrorMessage } from '@/utils/errors';
import { getWelcomePath } from '@/utils/querystrings';
import { cn } from '@/utils/tailwind';
import { AiModal } from '@/pages/apps/create-app/ai-form/AiModal';
import {
  AppCreateType,
  BUILDER_NEXT_IMPORT_PATH,
  WelcomePath
} from '@/pages/apps/create-app/constants';

const options = [
  {
    id: AppCreateType.Scratch,
    icon: <BeakerIcon size={24} />
  },
  {
    id: AppCreateType.Ai,
    icon: <AiStarsIcon className="rotate-90 fill-[url(#svg-gradient-2)]" size={24} />
  },
  {
    id: AppCreateType.Import,
    icon: (
      <>
        <ImportIcon size={24} />
        <GoogleSheetsIcon size={24} className="!fill-[#23A566]" />
      </>
    )
  }
];

export function WelcomeDialog() {
  const path = getWelcomePath();
  const [isVisible, setVisible] = useState(path && path !== WelcomePath.Template);
  const navigate = useNavigate();
  const location = useLocation();
  const [t] = useTranslation();
  const [selectedOption, setSelectedOption] = useState<AppCreateType | ''>(
    path === WelcomePath.Ai ? AppCreateType.Ai : ''
  );
  const [appCreationError, setAppCreationError] = useState('');
  const { createApp } = useAppMutation();
  const session = useSession();
  const [shouldShowAiModal, setShouldShowAiModal] = useState(false);

  if (!isVisible) {
    return null;
  }

  const onOpenChange = (open: boolean) => {
    setVisible(open);

    if (!open) {
      const params = new URLSearchParams(location.search);
      params.delete('welcomePath');
      navigate(
        {
          pathname: location.pathname,
          search: params.toString()
        },
        { replace: true }
      );
    }
  };

  const handleCreateApp = () => {
    setAppCreationError('');

    if (selectedOption === '') return;

    if (selectedOption === AppCreateType.Ai) {
      setShouldShowAiModal(true);
      return;
    }

    const data = {
      appDescription: '',
      appOrigin: selectedOption
    };

    createApp.mutate(data, {
      onSuccess: (response) => {
        window.location.href = `${selectedOption === AppCreateType.Import ? import.meta.env.PUBLIC_BUILDER_NEXT_URL : import.meta.env.PUBLIC_BUILDER_URL}/${session.account.slug}/${
          response.application.slug
        }/${selectedOption === AppCreateType.Import ? BUILDER_NEXT_IMPORT_PATH : ''}?new=true`;
      },
      onError: (error) => {
        const message = getErrorMessage(error, t('components.create_app.create_app_error_message'));
        setAppCreationError(message);
      }
    });
  };

  return (
    <Dialog open={isVisible} onOpenChange={onOpenChange}>
      <Dialog.Content className="md:max-w-[660px]">
        <Dialog.MainContent>
          <Dialog.Header>
            <h3 className="text-xl">{t('components.apps.welcome.title')}</h3>
          </Dialog.Header>
          {appCreationError && (
            <Banner
              intent="destructive"
              icon={ExclamationIcon}
              title={t('components.create_app.create_app_error_title')}
              className="my-4"
              closeMode="text"
            >
              <Banner.Message className="text-xs">{appCreationError}</Banner.Message>
            </Banner>
          )}
          <div className="mt-6 flex flex-col gap-4">
            <p className="font-normal">{t('components.apps.welcome.description')}</p>
            <div className="flex flex-col gap-4 sm:flex-row">
              {options.map((option) => (
                <Button
                  key={option.id}
                  className={cn(
                    'h-auto bg-transparent text-left *:h-auto *:p-4 focus:outline-0 focus-visible:ring-0',
                    {
                      'bg-gradient-1': selectedOption === option.id,
                      'after:absolute after:inset-0 after:z-10 after:-m-0.5 after:rounded-md after:bg-white after:opacity-70':
                        createApp.isPending
                    }
                  )}
                  intent="outline-gradient"
                  disabled={createApp.isPending}
                  onClick={() =>
                    setSelectedOption((prev) =>
                      prev === option.id || createApp.isPending ? '' : option.id
                    )
                  }
                >
                  <div className="flex flex-col gap-4">
                    <div
                      className={cn('flex gap-2', {
                        '*:fill-[url(#svg-gradient-1)]': selectedOption === option.id
                      })}
                    >
                      {option.icon}
                    </div>
                    <div className="flex flex-col gap-1">
                      <div
                        className={cn('font-medium text-emphasis', {
                          'text-gradient-1': selectedOption === option.id
                        })}
                      >
                        {t(`components.apps.welcome.options.${option.id}`)}
                      </div>
                      <div
                        className={cn('text-subtle', {
                          'text-gradient-1': selectedOption === option.id
                        })}
                      >
                        {t(`components.apps.welcome.options.${option.id}_description`)}
                      </div>
                    </div>
                  </div>
                </Button>
              ))}
            </div>
          </div>
        </Dialog.MainContent>
        <Dialog.Footer className="gap-2">
          <Button
            intent="minimal"
            disabled={createApp.isPending}
            onClick={() => onOpenChange(false)}
          >
            {t('actions.cancel')}
          </Button>
          <Button
            isLoading={createApp.isPending}
            onClick={() => handleCreateApp()}
            disabled={!selectedOption}
          >
            {t('components.apps.welcome.create')}
          </Button>
        </Dialog.Footer>
      </Dialog.Content>

      {shouldShowAiModal && <AiModal open onClose={() => setShouldShowAiModal(false)} />}
    </Dialog>
  );
}
